import React, { useState, useEffect, useContext, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';
import {
  FormGroup,
  LinearProgress,
  Link,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import VegaChart from './VegaChart';

import {
  renderBoilerPlate,
  getApiHost,
  fetchApiRequest,
} from './Common';
import NotificationUtils from '../libs/NotificationUtils';
import PrettyTable, { ColumnType } from '../libs/PrettyTable';
import { UserContext } from './UserContext';

const tableColumns: ColumnType[] = [
  {
    name: 'timestamp',
    header: 'Timestamp',
    format: v => v.substring(0, 19).replace('T', ' '),
    align: 'left'
  },
  {
    name: 'cosine_similarity',
    header: 'Cosine Similarity',
    format: v => v.toFixed(4),
    align: 'right',
    color: '0, 128, 0',
    zeroBased: true,
  },
  {
    name: 'window_deviation',
    header: 'Window Deviation',
    format: v => v.toFixed(4),
    align: 'right',
    color: '0, 0, 255',
  },
];


const AnomalyVisualization: React.FC<RouteComponentProps> = () => {
  const [ notificationId, setNotificationId ] = useState(null);
  const [ data, setData ] = useState(null);
  const [ unsaved, setUnsaved ] = useState(false);
  const [ simChart, setSimChart ] = useState(null);
  const [ tableEvents, setTableEvents ] = useState(null);
  const [ eventTime, setEventTime ] = useState(null);
  const [ view, setView ] = useState(null);
  const [ error, setError] = useState(null);
  const user = useContext(UserContext);
  const defaultData = {
    anomalyThreshold: 0.8,
    anomalyWindowSize: 3,
  };

  const loadEndpointData = (endpoint, dataCallback) => {
    if (!notificationId) return;
    const params = new URLSearchParams();
    params.set('notification_id', notificationId.toString());
    getApiHost(apiHost => {
      const url = `${apiHost}/v1/${endpoint}?${params.toString()}`;
      fetchApiRequest(url, fetch => fetch
        .then((res) => {
          if (res.ok)
            res.json().then(dataCallback);
          else
            res.text().then((error) => {
              setError(`API ${endpoint} ${res.status} error: ${error}`);
            });
        })
        .catch((error) => {
          setError(`API ${endpoint} fetch error: ${error}`);
        })
      );
    });
  };

  const loadPage = () => {
    loadSettings();
    loadSimChart();
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setNotificationId(queryParams.get('notification_id'));
    let eventTime = queryParams.get('event_time');
    if (!eventTime)
      eventTime =new Date().toISOString();
    setEventTime(eventTime);
  }, [location.search]);

  useEffect(loadPage, [notificationId]);

  const loadSettings = () => {
    // no need to setData(null) since it should already have the loaded values
    loadEndpointData('notification', setData);
  };

  const loadSimChart = () => {
    setSimChart(null);
    setTableEvents(null);
    loadEndpointData(
      'anomaly_history_chart',
      data => {
        setSimChart(JSON.parse(data.chart));
        const headers = ["timestamp", "cosine_similarity", "window_deviation"];
        const similarities = JSON.parse(data.similarities);
        setTableEvents({
          columns: tableColumns,
          rows: similarities,
        });
      }
    );
  };

  const unloadNotification = () => {
    // do nothing, only applicable for the notification page
  }

  const handleListLink = (event) => {
    event.preventDefault();
    if (unsaved) {
      if (!window.confirm(
          'You have unsaved changes. Are you sure that you want to leave ' +
          'this page before saving?'))
        return;
    }
    const params = new URLSearchParams();
    params.set('notification_id', notificationId);
    const newPath = `/?${params.toString()}#/notification`;
    window.location.href = newPath;
  }

  const moseoverListener = {
    event: 'mouseover',
    handler: (event, value) => {
      if (value && value.datum && value.shape) {
        const timestamp = new Date(value.datum.timestamp);
        setEventTime(timestamp.toISOString());
      }
    }
  }

  const tableFilterUpdate = ({ startTime, endTime }) => {
    if (view) {
      const first_ts = new Date(tableEvents.rows[0].timestamp).getTime();
      const start_ts = (new Date(startTime).getTime() - first_ts) / 1000;
      const end_ts = (new Date(endTime).getTime() - first_ts) / 1000;
      view.signal('highlight_start', start_ts);
      view.signal('highlight_end', end_ts);
      view.run();
      console.log(`foo ${start_ts} ${end_ts}`)
      console.log(view.getState().signals);
    }
  }

  const componentProps = {
    data, setData, defaultData, setError, loadPage,
    unsaved, setUnsaved, unloadNotification };
  const utils = new NotificationUtils(componentProps);

  return renderBoilerPlate(
    <><Grid container>
      <Grid item>
        {utils.renderPaper("Notification anomaly history",
          <>
            {data === null ? (
              <LinearProgress key="loading" />
            ) : (
              <form onSubmit={utils.handleSave}>
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container direction="column">
                      {utils.renderAnomalySettings({listPage: false})}
                      {utils.renderButtons({listPage: false})}
                    </Grid>
                  </LocalizationProvider>
                </FormGroup>
              </form>
            )}
            <p style={{marginBottom: 0}}><Link
                onClick={handleListLink}
                style={{ cursor: 'pointer' }}
              >Back to list of notifications</Link></p>
          </>
        )}
      </Grid>
      <Grid item>
        {utils.renderPaper("Anomaly events chart",
          <>
            {simChart === null ? (
              <LinearProgress key="loading" />
            ) : (
              <Grid container direction="column">
                <VegaChart
                  spec={simChart}
                  listeners={[moseoverListener]}
                  setView={setView} />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid item>
        {utils.renderPaper("Anomaly events data",
          <>
            {tableEvents === null ? (
              <LinearProgress key="loading" />
            ) : (
              <PrettyTable
                data={tableEvents}
                maxEvents={10}
                eventTime={eventTime}
                timestampName={'timestamp'}
                filterUpdate={tableFilterUpdate}
                sx={{ margin: 0, padding: 0 }} />
            )}
          </>
        )}
      </Grid>
      {error && (
        <MuiAlert elevation={6} variant="filled" severity="error">
          ERROR: {error}
        </MuiAlert>
      )}
    </Grid></>
  );
}

export default withRouter(AnomalyVisualization);

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link as MaterialLink } from '@mui/material';
import { UserContext } from './UserContext.tsx';

import { AuthMenu, LoginMenu } from './Menu';

const logo = require('../images/logo-200x39-blue-outline.png');

const styles = (theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
  },
  logo: {
    paddingLeft: 0,
    paddingTop: '8px',
    paddingBottom: 0,
  },
  tabItem: {
    paddingTop: '8px',
    paddingBottom: 0,
    minWidth: 'auto',
  },
});

class Topbar extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = { value: 0 };
    this.renderToolbar = this.renderToolbar.bind(this);
    this.menu = undefined;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  current = () => {
    const { location } = this.props;
    let largestMatch = this.menu.map((m, i) => {
      let length = 0;
      if (location.pathname.startsWith(m.pathname))
        length = m.pathname.length;
      return {index: i, length: length};
    }).sort((a, b) => a.length < b.length ? 1 : -1)[0];
    if (largestMatch.length > 0)
      return largestMatch.index;
    else
      return this.menu.findIndex(m => m.pathname.endsWith('login'));
  };

  renderToolbar() {
    const { classes, location } = this.props;
    const { value: stateValue } = this.state;
    const user = this.context;
    this.menu = (user.profile !== undefined) ? AuthMenu : LoginMenu;
    if (user.profile && user.profile.org === 'famb') {
      // remove annotation menus for Famb customers
      this.menu = this.menu.filter(item => item.label !== 'Annotations');
    }
    return (
      <Toolbar style={{paddingLeft: '20px', paddingRight: '5px'}}>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'}}>
          <div style={{display: 'flex'}}>
            <div>
              <Link to="/">
                <img
                  width={200}
                  src={logo}
                  alt="Safe Reaction"
                  className={classes.logo}
                />
              </Link>
            </div>
            <div style={{ marginLeft: 30 }}>
              <Tabs
                value={this.current() || stateValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChange}
              >
                { this.menu.map((item) => (
                  <Tab
                    key={item.pathname}
                    component={item.external ? MaterialLink : Link}
                    href={item.external ? item.pathname : null}
                    to={
                      item.external
                        ? null
                        : {
                            pathname: item.pathname,
                            search: location.search,
                          }
                    }
                    classes={{ root: classes.tabItem }}
                    label={item.label}
                  />
                )) }
              </Tabs>
            </div>
          </div>
          <div style={{ fontSize: "70%", fontStyle: "italic", paddingLeft: "1rem",
              paddingTop: '0.7rem', paddingRight: '0.5rem', textAlign: 'right' }}>
              {user.profile && (<>
                <nobr>Welcome {user.profile.givenName}!</nobr><br/>
                <nobr>{user.profile.email}</nobr><br/>
              </>)}
          </div>
        </div>
      </Toolbar>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="static" color="default" className={classes.appBar}
        style={{backgroundColor: 'white'}} sx={{m: 0, p: 0}}>
        {this.renderToolbar()}
      </AppBar>
    );
  }
}

export default withRouter(withStyles(styles)(Topbar));

import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import ScrollToTop from './components/ScrollTop';
import AnnotationEvents from './components/AnnotationEvents';
import Event from './components/Event';
import NotificationComponent from './components/Notification.tsx';
import AnomalyComponent from './components/Anomaly.tsx';
import Live from './components/Live';
import Login from './components/Login.tsx';
import { UserContext } from './components/UserContext.tsx';

function Routes() {
  return (
    <HashRouter>
      <ScrollToTop>
        <UserContext.Consumer>
          {(context) => {
            // redirect to login page unless authenticated
            const curLocation = window.location.toString();
            if (context.loaded) {
              if (!context.profile && !curLocation.endsWith('/login')) {
                const params = new URLSearchParams();
                params.set('redir', window.location);
                const url = `${
                  window.location.origin
                }/?${params.toString()}#/login`;
                window.location.replace(url);
              }
            }

            const routes = context.profile ? (
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/annotations" component={AnnotationEvents} />
                <Route exact path="/annotations/event" component={Event} />
                <Route
                  exact
                  path="/notification"
                  component={NotificationComponent}
                />
                <Route
                  exact
                  path="/notification/anomaly"
                  component={AnomalyComponent}
                />
                <Route exact path="/live" component={Live} />
                <Route exact path="/login" component={Login} />
              </Switch>
            ) : (
              <Route component={Login} />
            );
            return routes;
          }}
        </UserContext.Consumer>
      </ScrollToTop>
    </HashRouter>
  );
}

export default Routes;

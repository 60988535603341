/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable radix */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import ReactAudioPlayer from 'react-audio-player';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MuiAlert from '@mui/material/Alert';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Cookies from 'universal-cookie';
import Chart from 'react-google-charts';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  TableCellHead,
  ifdef,
  toString,
  SnackAlert,
  getApiHost,
  getLocationParam,
  renderBoilerPlate,
  TooltipTableCell,
  updateLocationSearchParams,
  loadingBackdrop,
  buildRequestsUrl,
  loadEvents,
  fetchApiRequest,
} from './Common';
import { setLocationState, buildLocationParams } from './AnnotationEvents';
import { UserContext } from './UserContext.tsx';

let eventComponent = null;
let annotationComponent = null;
let explanationComponent = null;

class Event extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      event: {},
      annotationRequests: undefined,
      loading: false,
      warning: null,
      deleteConfirmation: false,
    };
    setLocationState(this.state);
    this.audioUrl = getLocationParam('audio_url');
    this.audioIndex = getLocationParam('audio_index');
    if (this.audioIndex !== null) this.audioIndex = parseInt(this.audioIndex);
    this.navigateEvent = this.navigateEvent.bind(this);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    this.handleFindSimilar = this.handleFindSimilar.bind(this);
    this.predictCurrentScores = this.predictCurrentScores.bind(this);
    this.showTimestamp = this.showTimestamp.bind(this);
    this.cookies = new Cookies();
    eventComponent = this;
  }

  componentDidMount() {
    if (this.audioUrl === null)
      this.setState({
        warning:
          'No audio selected! Please click link ' +
          'in the dashboard or the annotations page',
      });

    // fetch existing data from bigquery
    getApiHost(apiHost => {
      this.audioControlUrl = `${apiHost}/v1/audio?` +
        `audio_url=${this.audioUrl}&audio_format=mp3`;
      this.eventsUrl = `${apiHost}/v1/annotation_requests`;
      this.predictEventUrl = `${apiHost}/v1/predict_event?` +
        `audio_url=${this.audioUrl}&reuse_preds=true`;
      fetchApiRequest(this.predictEventUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => {
          if (data.current_events === null)
            data.current_events = undefined;
          this.setState({ event: data }, () =>
            annotationComponent.fillAnnotation(),
          )},
        )
        .catch((e) => console.log(`Detch event error: ${e}`))
      );
    });
  }

  predictCurrentScores() {
    getApiHost(apiHost => {
      const predictEventUrl = (`${apiHost}/v1/predict_event?` +
          `audio_url=${this.audioUrl}&reuse_preds=false`);
      fetchApiRequest(predictEventUrl, fetch => fetch
        .then(res => res.json())
        .then(data => {
            this.setState(
              { event: data },
              explanationComponent.loadExplanations
            )})
        .catch((e) => console.log(`Detch event error: ${e}`))
      );
    });
  }

  handleNext = () => this.navigateEvent(1);

  handlePrev = () => this.navigateEvent(-1);

  navigateEvent(relativeIndex) {
    const offset = this.audioIndex + relativeIndex;
    if (offset < 0)
      this.setState({
        warning: 'Already on the first event, no more previous events',
      });
    else {
      this.setState({ loading: true });
      const params = buildLocationParams(this.state);
      params.set('offset', offset);
      params.set('limit', 10); // ask for ten to set the cookie cache
      const saferAnnotationsCache = this.cookies.get('saferAnnotationsCache');
      if (saferAnnotationsCache && offset < saferAnnotationsCache.length) {
        if (relativeIndex === 0) {
          saferAnnotationsCache.splice(offset, 1);
          this.cookies.set('saferAnnotationsCache', saferAnnotationsCache, {
            maxAge: 3600,
            sameSite: 'lax',
          });
        }
        updateLocationSearchParams({
          audio_index: offset,
          audio_url: saferAnnotationsCache[offset],
        });
      } else {
        buildRequestsUrl(this.state, params, this.context, url => {
          fetchApiRequest(url, fetch => fetch
            .then((res) => res.json())
            .then((data) => {
              if (data.requests[0] !== undefined) {
                const audioUrl = data.requests[0].url;
                const newCache = data.requests.map((c) => c.url);
                this.cookies.set('saferAnnotationsCache', newCache, {
                  maxAge: 3600,
                  sameSite: 'lax',
                });
                updateLocationSearchParams({
                  audio_url: audioUrl,
                  audio_index: 0,
                });
              } else {
                this.setState({
                  loading: false,
                  warning:
                    `Unable to navigate to index ${relativeIndex}. ` +
                    `API response: ${JSON.stringify(data)}`,
                });
              }
            })
            .catch((error) => {
              console.log(`Fetch event error: ${error}`);
              this.setState({
                loading: false,
                warning: `Fetch event error: ${error}`,
              });
            })
          );
        });
      }
    }
  }

  calcAudioLength() {
    if (this.state.event.audio_shape == null)
      return 'Empty audio, unknown number of seconds';
    // eslint-disable-next-line react/destructuring-assignment
    const shape0 = this.state.event.audio_shape.match(/\d+/)[0];
    const seconds = shape0 / 12000;
    const minutes = Math.floor(seconds / 60);
    let remSeconds = seconds - minutes * 60;
    remSeconds = Math.round(remSeconds * 1000) / 1000;
    if (minutes > 0) return `${minutes} minutes ${remSeconds} seconds`;
    return `${remSeconds} seconds`;
  }

  render() {
    return renderBoilerPlate(
      <>
        <Grid container>
          <Grid item>{this.annotationPaper()}</Grid>
          <Grid item>{this.eventPaper()}</Grid>
          <Grid item>{this.annotationRules()}</Grid>
          <Grid item>
            <ExplanationPaper />
          </Grid>
        </Grid>
        {loadingBackdrop({ open: this.state.loading })}
      </>,
    );
  }

  handleDeleteEvent() {
    this.setState({ loading: true, deleteConfirmation: false });
    getApiHost(apiHost => {
      const url = `${apiHost}/v1/predict_event?audio_url=${this.audioUrl}`;
      const callback = fetch => fetch
        .then((res) => res.json())
        .then(() => this.navigateEvent(0))
        .catch((e) => console.log(`fetch delete error: ${e}`));
      fetchApiRequest(url, callback, { method: 'delete' });
    });
}

  handleFindSimilar() {
    const params = new URLSearchParams(window.location.search);
    params.delete('audio_url');
    params.delete('audio_index');
    params.set('similar', this.audioUrl);
    const similarPath = `/?${params.toString()}#/annotations`;
    window.location = similarPath;
  }

  eventPaper() {
    return (
      <Paper>
        <Typography variant="subtitle1">Event Metadata</Typography>
        {this.eventTable()}
        <SnackAlert parent={this} state="warning" severity="warning">
          WARNING: {this.state.warning}
        </SnackAlert>
      </Paper>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  blankLink(url, text) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }

  renderDeleteButton() {
    return (
      <>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={this.handleFindSimilar}
        >
          Similar
        </Button>

        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={() => this.setState({ deleteConfirmation: true })}
          disabled={this.state.deleteConfirmation}
        >
          Delete
        </Button>

        <Dialog
          open={this.state.deleteConfirmation}
          onClose={() => this.setState({ deleteConfirmation: false })}
          aria-labelledby="delete-dialog"
        >
          <DialogTitle id="delete-dialog">Delete Event</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete this event?
              <br />
              <br />
              <code style={{ fontSize: '90%' }}>{this.audioUrl}</code>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={() => this.setState({ deleteConfirmation: false })}
            >
              Cancel
            </Button>
            <Button color="secondary" onClick={this.handleDeleteEvent}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  showTimestamp(timestamp, label, isUtc) {
    if (!timestamp) return;
    const timezoneString = isUtc ? ' UTC' : '';
    return (
      <TableRow>
        <TableCellHead>{label}</TableCellHead>
        <TableCell>{timestamp.substring(0, 19)} {timezoneString}</TableCell>
      </TableRow>
    );
  }

  eventTable() {
    if (Object.keys(this.state.event).length === 0) return <LinearProgress />;
    if (this.state.event.detail !== undefined)
      return (
        <>
          <MuiAlert elevation={6} variant="filled" severity="error">
            ERROR: {this.state.event.detail}
          </MuiAlert>
          {this.renderDeleteButton()}
        </>
      );

    return (
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCellHead>Length</TableCellHead>
              <TableCell>{this.calcAudioLength()}</TableCell>
            </TableRow>

            {this.showTimestamp(
              this.state.event.server_timestamp, 'Server Timestamp', true)}
            {this.showTimestamp(
              this.state.event.client_timestamp, 'Client Timestamp')}

            <TableRow>
              <TableCellHead>Device</TableCellHead>
              <TooltipTableCell>{this.state.event.device_id}</TooltipTableCell>
            </TableRow>

            <TableRow>
              <TableCellHead>Place</TableCellHead>
              <TableCell>
                IP:{' '}
                {this.blankLink(
                  `https://www.whois.com/whois/${this.state.event.host_ip}`,
                  this.state.event.host_ip,
                )}{' '}
                &nbsp; LOC:{' '}
                <Tooltip
                  title={`Original location: ${this.state.event.location}`}
                >
                  {this.blankLink(
                    `https://www.google.com/maps/place/${this.state.event.clean_location}`,
                    this.state.event.clean_location,
                  )}
                </Tooltip>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellHead>Class Label</TableCellHead>
              <TooltipTableCell>{ifdef(this.state.event.class_label)}</TooltipTableCell>
            </TableRow>

            <Tooltip title="Last prediction scores before current scores">
              <TableRow>
                <TableCellHead>Last Scores</TableCellHead>
                <TableCell>
                  {ifdef(this.state.event.events, (events) =>
                    events.map((e, i) => (
                      <span key={i}>
                        {ifdef(this.state.event.scores[i], (e_) =>
                          e_.toPrecision(5),
                        )}{' '}
                        {e}
                        <br />
                      </span>
                    )),
                  )}
                </TableCell>
              </TableRow>
            </Tooltip>

            <Tooltip title="Current scores performed with this request">
              <TableRow>
                <TableCellHead>Current Scores</TableCellHead>
                <TableCell>
                  <Grid container>
                    <div>{
                      (typeof this.state.event.current_events === 'undefined'
                      && <LinearProgress style={{width: '8rem'}} />)
                      || (this.state.event.current_events.map((e, i) => (
                          <span key={i}>
                            {e[1].toPrecision(5)} {e[0]}
                            <br />
                          </span>
                        )))
                    }</div>
                  </Grid>
                </TableCell>
              </TableRow>
            </Tooltip>

            <Tooltip title="Scores from the time of the last annotation">
              <TableRow>
                <TableCellHead>Annotation Scores</TableCellHead>
                <TableCell>
                  {ifdef(this.state.annotationRequests, (events) =>
                    events.map((e, i) => (
                      <span key={i}>
                        {e[1].toPrecision(5)} {e[0]}
                        <br />
                      </span>
                    )),
                  )}
                </TableCell>
              </TableRow>
            </Tooltip>
          </TableBody>
        </Table>

        <Grid container justify="space-evenly" style={{ marginTop: '1rem' }}>
          {this.audioIndex !== null && (
            <>
              <Button type="button" variant="contained"
                onClick={this.handlePrev}>
                Prev
              </Button>
              <Button type="button" variant="contained"
                onClick={this.handleNext}>
                Next
              </Button>
            </>
          )}
          {this.renderDeleteButton()}
        </Grid>
      </TableContainer>
    );
  }

  annotationPaper() {
    if (this.state.event.detail !== undefined)
      return (
        <MuiAlert elevation={6} variant="filled" severity="error">
          ERROR: {this.state.event.detail}
        </MuiAlert>
      );
    return (
      <Paper>
        <Typography variant="subtitle1">
          Annotate Event&nbsp; [{this.blankLink(this.audioControlUrl, 'MP3')}]
        </Typography>
        <ReactAudioPlayer autoPlay controls src={this.audioControlUrl} />
        <AnnotationForm />
      </Paper>
    );
  }

  annotationRules() {
    return (
      <Paper>
        <Typography variant="subtitle1">Annotation Rules</Typography>
        <Typography
          variant="body1"
          style={{ maxWidth: '23rem', fontSize: '0.8rem' }}
        >
          Please try adhering to the following principles to make the
          annotations as consistent as possible and avoid confusing the model.
        </Typography>
        <ul style={{ maxWidth: '23rem', fontSize: '0.8rem' }}>
          <li>
            If <strong>Casual</strong>, then do not select any other event
          </li>
          <li>
            If <strong>Gunshot</strong>, then do not select{' '}
            <strong>Human activity</strong> unless there is some human activity
            except <strong>Gunshot</strong> occuring
          </li>
          <li>
            If <strong>Human speech</strong>, then only select{' '}
            <strong>Human activity</strong> if something else than speech occurs
          </li>
          <li>
            If <strong>Dog bark</strong> or similar animal sounds, then select
            <strong>Animal activity</strong>
          </li>
          <li>
            If <strong>Car sounds</strong> or similar vehicle sound, then select
            <strong>Vehicle activity</strong>
          </li>
          <li>
            If <strong>Ignore</strong> is selected, then that event will be
            excluded from the model training completely
          </li>
        </ul>
      </Paper>
    );
  }
}

class AnnotationForm extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      events: null,
      annotation: null,
      loading: false,
      submitted: false,
      resetted: false,
    };
    this.bq_annotation = null;
    this.audio_url = getLocationParam('audio_url');
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleClassLabelChange = this.handleClassLabelChange.bind(this);
    this.fillEvents = this.fillEvents.bind(this);
    this.loadAnnotations = this.loadAnnotations.bind(this);
    this.fillAnnotation = this.fillAnnotation.bind(this);
    this.updateAnnotations = this.updateAnnotations.bind(this);
    annotationComponent = this;
  }

  componentDidMount() {
    loadEvents(this.fillEvents, this.audio_url);
  }

  fillEvents(events) {
    const eventChecked = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const e of events) eventChecked[e.name] = false;
    this.setState(
      { events, event_checked: eventChecked },
      this.loadAnnotations(eventComponent.predictCurrentScores),
    );
  }

  loadAnnotations(callback) {
    // eslint-disable-next-line no-param-reassign
    if (callback === undefined) callback = () => {};
    const audio_url = this.audio_url;

    // fetch annotation data from bigquery
    getApiHost(apiHost => {
      const url = `${apiHost}/v1/annotation?url=${audio_url}`;
      fetchApiRequest(url, fetch => fetch
        .then((res) => res.json())
        .then((data) => {
          this.bq_annotation = data;
          this.fillAnnotation();
          callback();
        })
        .catch((e) => {
          console.log(`fetch event error: ${e}`);
        })
      );
    });
}

  fillAnnotation() {
    let annotation = this.bq_annotation;
    if (
      eventComponent.state.event.events === undefined ||
      annotation === null
    )
      return; // wait until event component has loaded

    // set annotation events in Event Data paper
    const annotationDetail = annotation.detail;
    if (annotationDetail === undefined) {
      eventComponent.setState({
        annotationRequests: Object.entries(annotation.events),
      });
    } else {
      // no annotatin in bigquery, try finding matching event class_label
      const matchingEvent = this.state.events.find(
        (e) => e.name === eventComponent.state.event.class_label,
      );
      annotation =
        matchingEvent !== undefined
          ? { class_label: [matchingEvent.name] }
          : null;
    }

    if (annotation === null) {
      // no valid class labels found, try with new predictions
      const matchingEvents = this.state.events.filter((e) =>
        eventComponent.state.event.events.find(p => p === e.name),
      );
      if (matchingEvents.length > 0)
        annotation = { class_label: matchingEvents.map((e) => e.name) };
      else annotation = null;
    }

    if (annotation === null) annotation = { class_label: [] };

    const eventChecked = { ...this.state.event_checked };
    for (const label in eventChecked) eventChecked[label] = false;
    for (const label of annotation.class_label) eventChecked[label] = true;
    annotation.url = this.audio_url;
    annotation.comment = toString(annotation.comment);
    if (annotationDetail && annotationDetail.toLowerCase().includes('error'))
      annotation.detail = annotationDetail;
    this.setState({
      event_checked: eventChecked,
      annotation,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // update annotation through the APi
    const annotation = { ...this.state.annotation };
    annotation.class_label = [];
    for (const [label, checked] of Object.entries(this.state.event_checked))
      if (checked) annotation.class_label.push(label);
    // Add current event scores to annotation
    annotation.events = eventComponent.state.event.events
      ? eventComponent.state.event.events.map((e, i) =>
        [e, eventComponent.state.event.scores[i]])
      : null;
    const body = JSON.stringify(annotation);
    const fetchInit = {
      credentials: 'include',
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    };
    this.setState({ loading: true });
    getApiHost(apiHost => {
      const url = `${apiHost}/v1/annotation?url=${this.audio_url}`;
      const callback = (fetch) => fetch
        .then(res => {
          if (res.ok) {
            res
              .json()
              .then(this.updateAnnotations)
              .catch((e) => console.log(`fetch event error: ${JSON.stringify(e)}`));
          } else {
            res.text().then((txt) => {
              this.setState({
                annotation: {
                  detail: `Submit annotation ${res.status} error: ${txt}`,
                },
              });
            });
          }
        });
      fetchApiRequest(url, callback, fetchInit);
    });
  }

  updateAnnotations() {
    this.setState({ loading: false, submitted: true });
    if (eventComponent.audio_index !== null) {
      if (eventComponent.state.annotated || eventComponent.state.similar)
        eventComponent.navigateEvent(1);
      else eventComponent.navigateEvent(0);
    } else this.loadAnnotations();
  }

  handleReset() {
    this.setState({ loading: true });
    this.loadAnnotations(() =>
      this.setState({ loading: false, resetted: true }),
    );
  }

  handleTextChange = (event) => {
    // react does not support nested states, but works with the spread operator
    // https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
    const annotation = { ...this.state.annotation };
    annotation[event.target.name] = event.target.value;
    this.setState({ annotation });
  };

  handleClassLabelChange = (event) => {
    const eventChecked = { ...this.state.event_checked };
    if (event.target.name === 'Casual' && event.target.checked) {
      // if checking Casual, turn off all other events
      for (let key in eventChecked)
        eventChecked[key] = false;
    }
    else if (event.target.name !== 'Casual' && event.target.checked) {
      // if non Casual selected, uncheck Casual
      eventChecked['Casual'] = false;
    }
    eventChecked[event.target.name] = event.target.checked;
    this.setState({ event_checked: eventChecked });
  };

  buildCheckbox(label, key) {
    return (
      <FormControlLabel
        label={label}
        key={key}
        control={
          <Checkbox
            checked={this.state.event_checked[label]}
            name={label}
            onChange={this.handleClassLabelChange}
          />
        }
      />
    );
  }

  getLastAnnotationInfo() {
    if (this.state.annotation.timestamp !== undefined)
      return (
        <span>
          This annotation was last updated &nbsp;
          {this.state.annotation.timestamp.substring(0, 19)}
          <br />
          by &lt;{this.state.annotation.annotator_cred || 'unknown'}&gt;
          from IP address {this.state.annotation.annotator_ip}
        </span>
      );
    return <span>No previous annotation was found</span>;
  }

  render() {
    if (
      eventComponent.state.event.events === undefined ||
      this.state.annotation === null
    )
      return <LinearProgress />;

    const halfEventsSize = Math.ceil(this.state.events.length / 2);
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Typography variant="body2">
            {this.getLastAnnotationInfo()}
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <Grid container direction="column">
                {this.state.events
                  .slice(0, halfEventsSize)
                  .map((e, i) => this.buildCheckbox(e.name, i))}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction="column">
                {this.state.events
                  .slice(halfEventsSize)
                  .map((e, i) => this.buildCheckbox(e.name, i))}
              </Grid>
            </Grid>
          </Grid>

          <TextField
            variant="outlined"
            label="Optional comment"
            name="comment"
            margin="dense"
            multiline
            size="small"
            value={this.state.annotation.comment}
            onChange={this.handleTextChange}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          />

          <Grid container justify="space-evenly">
            <Button
              type="button"
              variant="contained"
              color="warning"
              onClick={this.handleReset}
            >
              Reset
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Annotate
            </Button>
          </Grid>

          <SnackAlert parent={this} state="submitted" severity="success">
            The annotations have been saved!
          </SnackAlert>

          <SnackAlert parent={this} state="resetted" severity="warning">
            The annotations have been resetted to last state!
          </SnackAlert>

          {this.state.annotation.detail !== undefined && (
            <MuiAlert elevation={6} variant="filled" severity="error">
              ERROR: <pre>{this.state.annotation.detail}</pre>
            </MuiAlert>
          )}
        </FormGroup>

        {loadingBackdrop({ open: this.state.loading })}
      </form>
    );
  }
}

class ExplanationPaper extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.top_event = '<Top event>';
    this.audio_url = getLocationParam('audio_url');
    this.cookies = new Cookies();
    this.state = {
      slices: null,
      enabled: this.getCookie('enabled', 'false') === 'true',
      alpha: this.getCookie('alpha', 0.4),
      sigma: this.getCookie('sigma', 8),
      steps: this.getCookie('steps', 20),
      event: this.top_event,
      events: [{ name: this.top_event }],
    };
    explanationComponent = this;
    this.loadExplanations = this.loadExplanations.bind(this);
    this.handleEnabledChange = this.handleEnabledChange.bind(this);
    this.handleEventChange = this.handleEventChange.bind(this);
    this.handleStepsChange = this.handleStepsChange.bind(this);
    this.handleAlphaChange = this.handleAlphaChange.bind(this);
    this.handleSigmaChange = this.handleSigmaChange.bind(this);
  }

  getCookie(name, defaultValue) {
    const value = this.cookies.get(name);
    return value === undefined ? defaultValue : value;
  }

  componentDidMount() {
    loadEvents(events => {
      events.unshift({ name: this.top_event });
      this.setState({ events });
    }, this.audio_url);
  }

  loadExplanations() {
    if (!this.state.enabled) return;
    this.setState({ slices: null });
    getApiHost((apiHost) => {
      let explainPredictUrl =
        `${apiHost}/v1/explain_predict` +
        `?audio_url=${this.audio_url}` +
        `&steps=${this.state.steps}` +
        `&sigma=${this.state.sigma}`;
      if (this.state.event !== this.top_event)
        explainPredictUrl += `&event=${this.state.event}`;
      fetchApiRequest(explainPredictUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) =>
          this.setState({ event: data.event, slices: data.slices }),
        )
        .catch((e) => console.log(`fetch events error: ${e}`))
      );
    });
  }

  updateStateAndCookie(state, callback) {
    this.setState(state, callback);
    Object.entries(state).map((e) =>
      this.cookies.set(e[0], e[1], { sameSite: 'lax' }),
    );
  }

  handleEnabledChange = (_e, v) =>
    this.updateStateAndCookie({ enabled: v }, this.loadExplanations);

  handleEventChange = (_e, v) =>
    this.updateStateAndCookie({ event: v.props.value }, this.loadExplanations);

  handleStepsChange = (_e, v) => this.updateStateAndCookie({ steps: v });

  handleAlphaChange = (_e, v) => this.updateStateAndCookie({ alpha: v });

  handleSigmaChange = (_e, v) => this.updateStateAndCookie({ sigma: v });

  render() {
    let content = [];
    const controls = [
      <FormControlLabel
        key="control-1"
        labelPlacement="start"
        style={{ height: '1.8rem', marginLeft: 0 }}
        control={
          <Switch
            checked={this.state.enabled}
            onChange={this.handleEnabledChange}
            color="primary"
            name="enable"
            style={{ bottom: '1px' }}
          />
        }
        label="Enable"
      />,
    ];
    if (this.state.enabled) {
      if (this.state.slices === null)
        content = [<LinearProgress key="loading" />];
      else {
        controls.push([
          <span key="enabled">
            <FormControlLabel
              label="Steps &nbsp;"
              labelPlacement="start"
              style={{ marginRight: '1rem' }}
              control={
                <Slider
                  value={parseInt(this.state.steps)}
                  aria-valuetext="Steps"
                  valueLabelDisplay="auto"
                  style={{ minWidth: '7rem' }}
                  onChange={this.handleStepsChange}
                  onChangeCommitted={this.loadExplanations}
                  step={1}
                  min={1}
                  max={200}
                />
              }
            />
            <FormControlLabel
              label="Alpha &nbsp;"
              labelPlacement="start"
              style={{ marginRight: '1rem' }}
              control={
                <Slider
                  value={parseFloat(this.state.alpha)}
                  aria-valuetext="Alpha"
                  valueLabelDisplay="auto"
                  style={{ minWidth: '7rem' }}
                  onChange={this.handleAlphaChange}
                  step={0.01}
                  max={1.0}
                />
              }
            />
            <FormControlLabel
              label="Smoothing &nbsp;"
              labelPlacement="start"
              style={{ marginRight: '1rem' }}
              control={
                <Slider
                  value={parseFloat(this.state.sigma)}
                  aria-valuetext="Smoothing sigma"
                  valueLabelDisplay="auto"
                  style={{ minWidth: '7rem' }}
                  onChange={this.handleSigmaChange}
                  onChangeCommitted={this.loadExplanations}
                  step={0.1}
                  max={20.0}
                />
              }
            />
            <FormControl style={{ marginLeft: '2rem' }}>
              <Select
                id="event"
                value={this.state.event}
                style={{ paddingBottom: 0 }}
                onChange={this.handleEventChange}
              >
                {this.state.events.map((e, i) => (
                  <MenuItem key={i} value={e.name}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Event</FormHelperText>
            </FormControl>
          </span>,
        ]);
        for (const i in this.state.slices) {
          const specSrc = `data:image/png;base64,${this.state.slices[i].spectrograms}`;
          const gradSrc = `data:image/png;base64,${this.state.slices[i].gradients}`;
          content.push(
            <div key={`p-${i}`} style={{ position: 'relative' }}>
              <img key={`spec-${i}`} alt="Spectrograms" src={specSrc} />
              <img
                key={`grad-${i}`}
                alt="Gradients"
                src={gradSrc}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  opacity: this.state.alpha,
                }}
              />
              <Grid container style={{ marginLeft: '33px' }}>
                <Grid item>
                  <Chart
                    width="430px"
                    height="200px"
                    chartType="AreaChart"
                    data={this.state.slices[i].histograms.inputs}
                    options={{
                      title: 'Histogram of inputs values',
                      legend: { position: 'bottom' },
                    }}
                  />
                </Grid>
                <Grid item>
                  <Chart
                    width="430px"
                    height="200px"
                    chartType="AreaChart"
                    data={this.state.slices[i].histograms.gradients}
                    options={{
                      title: 'Histogram of gradient values',
                      legend: { position: 'bottom' },
                    }}
                  />
                </Grid>
              </Grid>
            </div>,
          );
        }
      }
    }

    return (
      <Paper>
        <Typography variant="subtitle1">Explanation</Typography>
        <Grid container key="form-grid" style={{ marginLeft: 0 }}>
          {controls}
        </Grid>
        {content}
      </Paper>
    );
  }
}

export default withRouter(Event);

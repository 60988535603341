import React from 'react';
import { blue } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import { isProd } from './components/Common';
import { LoginContext, FakeLoginContext } from './components/UserContext.tsx';
import Routes from './routes';

// eslint-disable-next-line
const theme = createTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
    primary: {
      main: '#29235c',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
  props: {
    MuiPaper: {
      elevation: 2,
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          marginBottom: '0.5rem',
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        subtitle2: {
          marginTop: '1.5rem',
          marginBottom: '0.2rem',
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        body2: {
          marginBottom: '0.5rem',
          fontStyle: 'italic',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          margin: '0.5rem',
          padding: '0.8rem',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          whiteSpace: 'nowrap',
          padding: '0.3rem 1rem 0.3rem 0',
          maxWidth: '20rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    PrivateSwitchBase: {
      styleOverrides: {
        root: {
          padding: '0 0.5rem 0 0.5rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        selectMenu: {
          padding: '2px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingBottom: '2px',
          paddingTop: '2px',
          lineHeight: '1.2',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '2px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          marginRight: '0.3rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '0.4rem 0.4rem',
          width: '10rem',
        },
      },
    },
  },
});

const App = () => {
  const context = isProd()
    ? (children) => <LoginContext>{children}</LoginContext>
    : (children) => <FakeLoginContext>{children}</FakeLoginContext>;
  return context(
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>,
  );
};

export default App;

import React, { useEffect, useRef } from 'react';
import vegaEmbed from 'vega-embed';

const VegaChart = ({ spec, listeners, setView }) => {
  const chartRef = useRef();

  useEffect(() => {
    vegaEmbed(chartRef.current, spec, {actions: false})
      .then(({ view }) => {
        if (setView)
          setView(view);

        if (listeners)
          listeners.forEach(({ event, handler }) =>
            view.addEventListener(event, handler));
      })
      .catch(error => console.error(error));

    return () => {
      // Optional: cleanup function to remove the chart
    };
  }, [spec]);

  return <div ref={chartRef}></div>;
};

export default VegaChart;

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import {
  renderBoilerPlate,
  getApiHost,
  loadEvents,
  fetchApiRequest,
  zeroFill,
} from './Common';
import NotificationUtils, { NotificationData } from '../libs/NotificationUtils';
import { UserContext } from './UserContext';

// sync with safer/routes/schema.py, NotificationData
function renderPaper(title: string, content: React.ReactNode) {
  return (
    <Paper>
      <Typography variant="subtitle1">{title}</Typography>
      {content}
    </Paper>
  );
}

const DateLabel = styled(FormControlLabel)({
  width: '18rem', marginLeft: 'auto',
  fontWeight: 'bold', padding: '0.2rem 0.2rem'
});


function renderTextField(editing, name, description, tooltip, handler) {
  return (
    <Grid item>
      <Tooltip title={tooltip}>
        <TextField
          variant="outlined"
          label={description}
          size="small"
          name={name}
          value={editing[name]}
          onChange={handler}
          style={{ width: '20rem', marginBottom: '0.5rem' }}
          inputProps={{ style: { width: '100%' } }}
        />
      </Tooltip>
    </Grid>
  );
}

function renderDatePicker(params) {
  const dateFormat = "YYYY-MM-DD";
  return (
    <Grid item>
      <Tooltip title={params.tooltip}>
        <DateLabel
          label={<Typography sx={{width: '7rem'}}>{params.name}</Typography>}
          labelPlacement="start"
          sx={{marginLeft: 'auto'}}
          control={
            <DatePicker
              value={params.value}
              minDate={params.minValue}
              maxDate={params.maxValue}
              onChange={params.handler}
              format={dateFormat}
              disablePast
            />
          }
        />
      </Tooltip>
    </Grid>
  );
}

function renderTimePicker(params) {
  const timeFormat = "HH:mm";
  return (
    <Grid item>
      <Tooltip title={params.tooltip}>
        <DateLabel
          label={<Typography sx={{width: '7rem'}}>{params.name}</Typography>}
          labelPlacement="start"
          control={
            <TimePicker
              value={params.value}
              onChange={params.handler}
              format={timeFormat}
              ampm={false}
            />
          }
        />
      </Tooltip>
    </Grid>
  );
}

function buildCheckbox(label, state, handler) {
  let limitedLabel: string = label;
  if (limitedLabel.length > 15)
    limitedLabel = `${limitedLabel.substring(0, 13)}...`;
  return (
    <FormControlLabel
      label={limitedLabel}
      key={label}
      control={<Checkbox checked={state} name={label} onChange={handler} />}
    />
  );
}

function loadDevices(callback) {
  getApiHost(apiHost => {
    const url = `${apiHost}/v1/devices`;
    fetchApiRequest(url, fetch => fetch
      .then(res => {
        if (res.ok)
          res.json().then(devices => {
            callback(devices.map(d => ({name: d})));
          });
        else
          res.text().then(error => alert(
            `Fetch devices response error: ${error}`));
      })
      .catch(error => alert(`Fetch devices error: ${error}`))
    );
  });
}

const Notification: React.FC<RouteComponentProps> = () => {
  const [ loading, setLoading ] = useState(true);
  const [ availableEvents, setAvailableEvents] = useState(undefined);
  const [ availableDevices, setAvailableDevices] = useState(undefined);
  const defaultData = {
    dates: undefined,
    selectedEvents: undefined,
    selectedDevices: undefined,
    digest: "daily",
    minDigestTime: undefined,
    positivePercent: 80,
    checkMissingData: false,
    createdAt: undefined,
    checkAnomalyOnly: false,
    anomalyThreshold: 0.8,
    anomalyWindowSize: 3,
  };
  const [ data, setData] = useState(defaultData);
  const [ pageNum, setPageNum] = useState(0);
  const [ rowsPerPage, setRowsPerPage] = useState(10);
  const [ notifications, setNotifications] = useState(undefined);
  const [ unsaved, setUnsaved ] = useState(false);
  const [ error, setError] = useState(undefined);
  const user = useContext(UserContext);

  const handleAdd = () => {
    const newEditing = initNotification(data);
    setData(newEditing);
    setUnsaved(true);
  }

  const handleEdit = (notification: NotificationData) => {
    return () => {
      const newEditing = initNotification(notification);
      setData(newEditing);
      setUnsaved(false);  // everything just loaded from fs
      setListPagePath(notification.notificationId);
    };
  }

  const handleChangePage = (_event, newPage) => {
    setLoading(true);
    setPageNum(newPage);
    loadPage();
  }

  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    setPageNum(0);
    setRowsPerPage(+event.target.value);
    loadPage();
  }

  const handleDateChange = (name: string) => {
    return date => {
      if (date === null || !Number.isNaN(date.valueOf())) {
        const newEditing = { ...data };
        newEditing.dates[name] = date;
        if (date === null) newEditing[name] = null;
        else
          newEditing[name] = [
            date.year(),
            zeroFill(date.month() + 1),
            zeroFill(date.date()),
          ].join('-');
        setData(newEditing);
        setUnsaved(true);
      }
      return date;
    };
  }

  const handleTimeChange = (name: string) => {
    return time => {
      if (time === null || !Number.isNaN(time.valueOf())) {
        const newEditing = { ...data };
        newEditing.dates[name] = time;
        if (time === null) newEditing[name] = null;
        else
          newEditing[name] = [
            time.hour(),
            time.minute(),
          ].join(':');
        setData(newEditing);
        setUnsaved(true);
      }
    };
  }

  const handlePositivePercentChange = (event) => {
    const newEditing = { ...data };
    newEditing.positivePercent = event.target.value;
    setData(newEditing);
    setUnsaved(true);
  }

  const handleEventsChange = (event) => {
    const newEditing = { ...data };
    newEditing.selectedEvents[event.target.name] = event.target.checked;
    setData(newEditing);
    setUnsaved(true);
  }

  const handleDevicesChange = (event) => {
    const newEditing = { ...data };
    newEditing.selectedDevices[event.target.name] = event.target.checked;
    setData(newEditing);
    setUnsaved(true);
  }

  const handleMissingDataChange = (event) => {
    const newEditing = { ...data };
    newEditing[event.target.name] = event.target.checked;
    if (event.target.checked && data['digest'] === 'live')
      newEditing['digest'] = 'daily'
    setData(newEditing);
    setUnsaved(true);
  }

  const getPaginationText = ({ from, to }) => {
    let properTo = to;
    if (notifications !== undefined) {
      const offset = rowsPerPage * pageNum;
      if (to - offset > notifications.length)
        properTo = Math.max(from, offset + notifications.length);
    }
    return `Rows: ${from} - ${properTo}`;
  }

  const initNotification = (data) => {
    const newData = { ...data };

    // init text fields
    ['description', 'mail', 'sms'].forEach((field) => {
      if (newData[field] === undefined) newData[field] = '';
    });
    if (newData['mail'] === '' && user.profile)
      newData['mail'] = user.profile.email;

    // make sure editing has an initialized selectedEvents list
    if (!newData.selectedEvents) newData.selectedEvents = {};
    availableEvents.forEach((event) => {
      if (newData.selectedEvents[event.name] === undefined)
        newData.selectedEvents[event.name] = false;
    });

    // make sure editing has an initialized selectedDevices list
    if (!newData.selectedDevices) newData.selectedDevices = {};
    availableDevices.forEach((device) => {
      if (newData.selectedDevices[device.name] === undefined)
        newData.selectedDevices[device.name] = true;
    });

    // copy over from parsed fields into Date objects
    if (newData.dates === undefined) newData.dates = {};
    ['startDate', 'endDate'].forEach((name) => {
      if (data[name]) {
        const parts = data[name].split('-');
        const date = new Date();
        date.setFullYear(parseInt(parts[0]));
        date.setMonth(parseInt(parts[1]) - 1);
        date.setDate(parseInt(parts[2]));
        newData.dates[name] = dayjs(date);
      }
      else newData.dates[name] = null;
    });

    ['startTime', 'endTime', 'minDigestTime'].forEach((name) => {
      if (data[name]) {
        const parts = data[name].split(':');
        const date = new Date();
        date.setHours(parseInt(parts[0]));
        date.setMinutes(parseInt(parts[1]));
        newData.dates[name] = dayjs(date);
      }
      else newData.dates[name] = null;
    });

    return newData;
  }

  const buildParams = (notificationId) => {
    const params = new URLSearchParams();
    const offset = rowsPerPage * pageNum;
    params.set('offset', offset.toString());
    params.set('limit', rowsPerPage.toString());
    if (notificationId)
      params.set('notification_id', notificationId);
    return params
  }

  const setListPagePath = (notificationId) => {
    const params = buildParams(notificationId);
    const newPath = `/?${params.toString()}${window.location.hash}`;
    window.history.pushState({}, '', newPath);
    return {params: params, newPath: newPath};
  }

  const buildNotificationsUrl = useCallback(
    (notificationId: string, callback: Function) => {
    const params = buildParams(notificationId);
    getApiHost((apiHost) => {
      const url = `${apiHost}/v1/notifications?${params.toString()}`;
      callback(url);
    });
  }, [rowsPerPage, pageNum]);

  const unloadNotification = () => {
    setData(defaultData);
    setListPagePath(null);
  }

  const loadNotification = (notifications) => {
    const queryParams = new URLSearchParams(location.search);
    const notificationId = queryParams.get('notification_id');
    if (availableEvents && notificationId) {
      const notification = notifications.find(
        n => n.notificationId === notificationId);
      if (notification) {
        const newData = initNotification(notification);
        setData(newData);
        setUnsaved(false);
      }
    }
  };

  const loadPage = useCallback(() => {
    setLoading(true);
    buildNotificationsUrl(null, url => {
      fetchApiRequest(url, fetch => fetch
        .then((res) => {
          if (res.ok)
            res.json().then((notifications) => {
              setLoading(false);
              if (notifications.length === 0 && pageNum === 0)
                setNotifications(undefined);
              else {
                setNotifications(notifications);
                loadNotification(notifications);
              }
            });
          else
            res.text().then((error) => {
              setError(`Fetch notifications API ${res.status} error: ${error}`);
            });
        })
        .catch((error) => {
          setError(`Fetch notifications error: ${error}`);
        })
      );
    });
  }, [buildNotificationsUrl, pageNum, availableEvents, availableDevices]);

  const renderTextFields = () => {
    return (
      <>
        {renderTextField(
          data,
          'description',
          'Description',
          'Description of the notification',
          utils.stringChange,
        )}
        {renderTextField(
          data,
          'mail',
          'Mail address',
          'Mail address where notifications will be sent',
          utils.stringChange,
        )}
      </>
    );
  }

  const renderEventsField = () => {
    if (data.selectedEvents === undefined) return '';
    const halfSize = Math.ceil(availableEvents.length / 2);
    return (
      <Grid item>
        <Tooltip title="Select events to receive notifications for">
          <fieldset style={{ marginBottom: '0.5rem' }}>
            <legend>Events</legend>
            <Grid container>
              <Grid item xs={6}>
                <Grid container direction="column">
                  {availableEvents
                    .slice(0, halfSize)
                    .map((e) =>
                      buildCheckbox(
                        e.name,
                        data.selectedEvents[e.name],
                        handleEventsChange,
                      ),
                    )}
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="column">
                  {availableEvents
                    .slice(halfSize)
                    .map((e) =>
                      buildCheckbox(
                        e.name,
                        data.selectedEvents[e.name],
                        handleEventsChange,
                      ),
                    )}
                </Grid>
              </Grid>
            </Grid>
          </fieldset>
        </Tooltip>
      </Grid>
    );
  }

  const renderDevicesField = () => {
    if (data.selectedDevices === undefined) return '';
    const halfSize = Math.ceil(availableDevices.length / 2);
    return (
      <Grid item>
        <Tooltip title="Select devices to receive notifications for">
          <fieldset style={{ marginBottom: '0.5rem' }}>
            <legend>Devices</legend>
            <Grid container>
              <Grid item xs={6}>
                <Grid container direction="column">
                  {availableDevices
                    .slice(0, halfSize)
                    .map((d) =>
                      buildCheckbox(
                        d.name,
                        data.selectedDevices[d.name],
                        handleDevicesChange,
                      ),
                    )}
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="column">
                  {availableDevices
                    .slice(halfSize)
                    .map((d) =>
                      buildCheckbox(
                        d.name,
                        data.selectedDevices[d.name],
                        handleDevicesChange,
                      ),
                    )}
                </Grid>
              </Grid>
            </Grid>
          </fieldset>
        </Tooltip>
      </Grid>
    );
  }

  const renderScheduleFields = () => {
    if (data.dates === undefined) return '';
    return (
      <fieldset style={{ marginBottom: '0.5rem' }}>
        <legend>Schedule</legend>
        {renderDatePicker({
          name: 'Start date',
          tooltip: 'Date when the notifications should start',
          value: data.dates.startDate,
          maxValue: data.dates.endDate,
          handler: handleDateChange('startDate'),
        })}
        {renderDatePicker({
          name: 'End date',
          tooltip: 'Date when the notifications should end',
          value: data.dates.endDate,
          minValue: data.dates.startDate,
          handler: handleDateChange('endDate'),
        })}
        {renderTimePicker({
          name: 'Start time',
          tooltip: 'Time of day when notifications should start',
          value: data.dates.startTime,
          handler: handleTimeChange('startTime'),
        })}
        {renderTimePicker({
          name: 'End time',
          tooltip: 'Time of day when notifications should end',
          value: data.dates.endTime,
          handler: handleTimeChange('endTime'),
        })}
      </fieldset>
    );
  }

  const renderDigestFields = () => {
    if (data.dates === undefined) return '';
    return (
      <fieldset style={{ marginBottom: '0.5rem' }}>
        <legend>Digest options</legend>
        <RadioGroup
          aria-label="digest"
          name="digest"
          value={data.digest}
          onChange={utils.stringChange}
        >
          <FormControlLabel
            value="live"
            control={<Radio size="small" color="primary" />}
            label="Real-time notifications"
            disabled={data.checkMissingData}
          />
          <FormControlLabel
            value="hourly"
            control={<Radio size="small" color="primary" />}
            label="Hourly digests"
          />
          <FormControlLabel
            value="daily"
            control={<Radio size="small" color="primary" />}
            label="Daily digests"
          />
        </RadioGroup>
        <FormControlLabel
          label="Notify on missing data instead"
          key="checkMissingData"
          control={<Checkbox
            checked={data.checkMissingData}
            name="checkMissingData"
            onChange={handleMissingDataChange}
            />}
          style={{ marginLeft: '-0.3rem', marginTop: '1rem' }}
        />
        { data.digest == 'live' && (
        <div style={{marginTop: "1rem"}}>
          {renderTimePicker({
            name: 'Min time',
            tooltip: 'Minimal consecutive time for notification',
            value: data.dates.minDigestTime,
            handler: handleTimeChange('minDigestTime'),
          })}
          <Grid item>
            <Tooltip title="Percent of positive events during time period">
              <DateLabel
                label={<Typography sx={{width: '15rem'}}>
                  Positive events</Typography>}
                labelPlacement="start"
                control={
                  <Slider
                    size="small"
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    value={data.positivePercent}
                    onChange={handlePositivePercentChange}
                  />}
              />
            </Tooltip>
          </Grid>
        </div>
        )}
      </fieldset>
    );
  }

  const renderForm = () => {
    const paperTitle =
      data.createdAt === undefined
        ? 'Add notification'
        : 'Edit notification';

    return renderPaper(
      paperTitle,
      <Grid item>
        <form onSubmit={utils.handleSave}>
          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container direction="column">
                {renderTextFields()}
                {renderEventsField()}
                {renderDevicesField()}
                {renderScheduleFields()}
                {renderDigestFields()}
                {utils.renderAnomalySettings({listPage: true})}
                {utils.renderButtons({listPage: true})}
              </Grid>
            </LocalizationProvider>
          </FormGroup>
        </form>
      </Grid>,
    );
  }

  const renderNotifications = () => {
    const addButton = (
      <Button
        type="button"
        variant="contained"
        color="primary"
        style={{ marginTop: '1rem' }}
        onClick={handleAdd}
      >
        Add notification
      </Button>
    );
    const paperTitle = 'Existing notifications';

    if (loading)
      return renderPaper(paperTitle, <LinearProgress key="loading" />);

    if (notifications === undefined)
      return renderPaper(
        paperTitle,
        <>
          <Typography variant="body2">
            There are no existing notifications. Click &quot;Add
            notification&quot; button button to add new notifications.
          </Typography>
          {addButton}
        </>,
      );

    return renderPaper(
      paperTitle,
      <>
        <Typography variant="body2">
          List of existing notifications, click the description to edit.
        </Typography>
        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((n) => (
                <TableRow key={n.createdAt.toString()}>
                  <Tooltip title={n.description}>
                    <TableCell>
                      <Link
                        onClick={handleEdit(n)}
                        style={{ cursor: 'pointer' }}
                      >
                        {n.description || '<empty>'}
                      </Link>
                    </TableCell>
                  </Tooltip>
                  <TableCell>
                    {n.createdAt.toString().substring(0, 16)}
                  </TableCell>
                  <TableCell>
                    {n.updatedAt.toString().substring(0, 16)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPage={rowsPerPage}
            count={-1}
            labelDisplayedRows={getPaginationText}
            page={pageNum}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ margin: 0 }}
          />
        </TableContainer>
        {addButton}
      </>,
    );
  }

  useEffect(() => {
    loadEvents(setAvailableEvents);
    loadDevices(setAvailableDevices);
  }, []);

  useEffect(() => {
    loadPage();
  }, [availableDevices]);

  const componentProps = {
    data, setData, setLoading, setError, loadPage,
    unsaved, setUnsaved, unloadNotification };
  const utils = new NotificationUtils(componentProps);

  return renderBoilerPlate(
    <>
      <Grid container>
        <Grid item>{renderNotifications()}</Grid>
         {data.dates !== undefined && renderForm()}
      </Grid>

      {error != null && (
        <MuiAlert elevation={6} variant="filled" severity="error">
          ERROR: {error}
        </MuiAlert>
      )}
    </>,
  );
}

export default withRouter(Notification);

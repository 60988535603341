/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import Cookies from 'universal-cookie';

import { UserContext } from './UserContext.tsx';

import {
  fetchApiRequest,
  getLocationParam,
  TooltipTableCell,
  loadingBackdrop,
  renderBoilerPlate,
  buildRequestsUrl,
  zeroFill,
  loadEvents,
  loadOrgs,
} from './Common';

let eventsTableComponent = null;

export function setLocationState(state) {
  state.unconfident = getLocationParam('unconfident') === 'true';
  state.annotated = getLocationParam('annotated') === 'true';
  state.mispredictions = getLocationParam('mispredictions') === 'true';
  state.skipDays = getLocationParam('skip_days') || 0;
  state.predictEventFilter = getLocationParam('prediction') || 'all';
  state.annotationEventFilter = getLocationParam('annotation') || 'all';
  state.orgFilter = getLocationParam('org') || 'all';
  state.max_similarity = getLocationParam('max_similarity') || '0';
  state.class_label_filter = getLocationParam('class_label') || '';
  state.device_filter = getLocationParam('device') || '';
  state.similar = getLocationParam('similar');
  state.only_unannotated = getLocationParam('only_unannotated') === 'true';
  state.only_annotated = getLocationParam('only_annotated') === 'true';
  state.time_filter = {
    date_start: getLocationParam('date_start') || '',
    date_end: getLocationParam('date_end') || '',
    time_start: getLocationParam('time_start') || '',
    time_end: getLocationParam('time_end') || '',
    warnings: [],
  };

  const offset = parseInt(getLocationParam('offset')) || 0;
  const limit = parseInt(getLocationParam('limit')) || 100;
  state.pageNum = Math.floor(offset / limit);
  state.rowsPerPage = limit;
}

export function buildLocationParams(state) {
  const params = new URLSearchParams();
  const setParam = (key, value) => {
    if (value && value !== undefined)
      params.set(key, value);
  };
  setParam('unconfident', state.unconfident);
  setParam('annotated', state.annotated);
  setParam('mispredictions', state.mispredictions);
  setParam('skip_days', state.skipDays);
  setParam('prediction', state.predictEventFilter);
  setParam('annotation', state.annotationEventFilter);
  setParam('org', state.orgFilter);
  setParam('max_similarity', state.max_similarity);
  setParam('class_label', state.class_label_filter);
  setParam('device', state.device_filter);
  setParam('date_start', state.time_filter.date_start);
  setParam('date_end', state.time_filter.date_end);
  setParam('time_start', state.time_filter.time_start);
  setParam('time_end', state.time_filter.time_end);
  if (state.similar) {
    setParam('similar', state.similar);
    setParam('only_unannotated', state.only_unannotated);
    setParam('only_annotated', state.only_annotated);
  }
  setParam('max_similarity', state.max_similarity);
  setParam('date_start', state.time_filter.date_start);
  setParam('date_end', state.time_filter.date_end);
  setParam('time_start', state.time_filter.time_start);
  setParam('time_end', state.time_filter.time_end);
  return params;
}

const DateLabel = withStyles({
  label: { width: '6rem', fontWeight: 'bold' },
})(FormControlLabel);

class AnnotationEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    setLocationState(this.state);
  }

  render() {
    let header = null;
    if (this.state.similar) {
      const params = buildLocationParams(this.state);
      params.set('audio_url', this.state.similar);
      params.set('audio_index', 0);
      const eventLink = `/?${params.toString()}#/annotations/event`;
      params.delete('similar');
      const annReqLink = `/?${params.toString()}#/annotations`;
      header = (
        <>
          <Typography variant="subtitle1">Similar Requests</Typography>
          <Typography variant="body2">
            List of prediction requests that are most similar to{' '}
            <a href={eventLink}>this event</a>. Click on the timestamp link to
            annotate, or go back to <a href={annReqLink}>annotation requests</a>
            .
          </Typography>
          <SimilarEventsForm />
        </>
      );
    } else {
      header = (
        <>
          <Typography variant="subtitle1">Annotation Requests</Typography>
          <Typography variant="body2">
            List of prediction requests that are of most need of annotation.
            Click on the timestamp link to annotate.
          </Typography>
          <SelectEventsForm />
        </>
      );
    }
    return renderBoilerPlate(
      <Grid container>
        <Grid item>
          <Paper>
            {header}
            <EventsTable />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

class SelectEventsForm extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.all_events = '<All events>';
    this.all_orgs = '<All orgs>';
    this.state = {
      events: [{ name: this.all_events }],
      orgs: [{ name: this.all_orgs }],
      max_similarity: '0',
      class_label_filter: '',
      device_filter: '',
    };
    this.class_label_timeout = null;
    this.device_timeout = null;
    this.maxSimilarityItems = [
      { name: 'None', value: '0' },
      { name: '0.001', value: '0.001' },
      { name: '0.01', value: '0.01' },
      { name: '0.1', value: '0.1' },
    ];
    setLocationState(this.state);
    if (this.state.predictEventFilter === 'all')
      this.state.predictEventFilter = this.all_events;
    if (this.state.annotationEventFilter === 'all')
      this.state.annotationEventFilter = this.all_events;
    if (this.state.orgFilter === 'all')
      this.state.orgFilter = this.all_orgs;
    this.handleUnconfidentChange = this.handleUnconfidentChange.bind(this);
    this.handleAnnotatedChange = this.handleAnnotatedChange.bind(this);
    this.handleMispredictionsChange = this.handleMispredictionsChange.bind(
      this,
    );
    this.handleSkipDaysChange = this.handleSkipDaysChange.bind(this);
    this.handlePredFilterChange = this.handlePredFilterChange.bind(this);
    this.handleAnnFilterChange = this.handleAnnFilterChange.bind(this);
    this.handleOrgFilterChange = this.handleOrgFilterChange.bind(this);
    this.handleMaxSimChange = this.handleMaxSimChange.bind(this);
    this.handleCLFilterChange = this.handleCLFilterChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.validateTimeFilter = this.validateTimeFilter.bind(this);
    this.time_filter_timeout = null;
  }

  formControlStyle = { marginRight: '2rem', marginBottom: '1rem' };

  componentDidMount() {
    loadEvents(events => {
      events.unshift({ name: this.all_events });
      this.setState({ events: events });
    });
    loadOrgs(this.context, orgs => {
      orgs.unshift({ name: this.all_orgs });
      this.setState({ orgs: orgs });
    });
  }

  handleUnconfidentChange = (event) => {
    const newState = { unconfident: event.target.checked };
    this.setState(newState);

    const tableState = { ...newState, loading: true };
    eventsTableComponent.setState(
      tableState,
      eventsTableComponent.loadRequests,
    );
  };

  handleAnnotatedChange = (event) => {
    const newState = { annotated: event.target.checked };
    if (!event.target.checked) {
      newState.mispredictions = false;
      newState.annotationEventFilter = this.all_events;
    }
    this.setState(newState);

    const tableState = { ...newState, loading: true };
    if (tableState.annotationEventFilter === this.all_events)
      tableState.annotationEventFilter = 'all';
    eventsTableComponent.setState(
      tableState,
      eventsTableComponent.loadRequests,
    );
  };

  handleMispredictionsChange = (event) => {
    const newState = { mispredictions: event.target.checked };
    if (newState.mispredictions) newState.annotated = true;
    this.setState(newState);

    const tableState = { ...newState, loading: true };
    eventsTableComponent.setState(
      tableState,
      eventsTableComponent.loadRequests,
    );
  };

  handleSkipDaysChange = (event, newValue) => {
    this.setState({ skipDays: newValue });
  };

  handleSkipDaysCommit = (event, newValue) => {
    eventsTableComponent.setState(
      { skipDays: newValue, loading: true },
      eventsTableComponent.loadRequests,
    );
  };

  handlePredFilterChange = (event) => {
    this.setState({
      predictEventFilter: event.target.value,
      annotationEventFilter: this.all_events,
    });
    let newFilter = event.target.value;
    if (newFilter === this.all_events) newFilter = 'all';
    eventsTableComponent.setState(
      {
        predictEventFilter: newFilter,
        annotationEventFilter: 'all',
        loading: true,
      },
      eventsTableComponent.loadRequests,
    );
  };

  handleAnnFilterChange = (event) => {
    const newFilter = event.target.value;
    const thisState = {
      predictEventFilter: this.all_events,
      annotationEventFilter: newFilter,
    };
    const tableState = {
      annotationEventFilter: newFilter,
      predictEventFilter: 'all',
      loading: true,
    };
    if (newFilter === this.all_events) tableState.annotationEventFilter = 'all';
    else {
      // needs to enable annotated if annotation filter
      tableState.annotated = true;
      thisState.annotated = true;
    }
    this.setState(thisState);
    eventsTableComponent.setState(
      tableState,
      eventsTableComponent.loadRequests,
    );
  };

  handleOrgFilterChange = (event) => {
    const newFilter = event.target.value;
    const thisState = {
      orgFilter: newFilter,
    };
    const tableState = {
      orgFilter: newFilter,
      loading: true,
    };
    if (newFilter === this.all_orgs)
      tableState.orgFilter = 'all';
    this.setState(thisState);
    eventsTableComponent.setState(
      tableState,
      eventsTableComponent.loadRequests,
    );
  };

  handleMaxSimChange = (event) => {
    this.setState({ max_similarity: event.target.value });
    eventsTableComponent.setState(
      { max_similarity: event.target.value, loading: true },
      eventsTableComponent.loadRequests,
    );
  };

  handleCLFilterChange = (event) => {
    this.setState({
      class_label_filter: event.target.value,
      device_filter: '',
    });
    if (this.class_label_timeout) clearTimeout(this.class_label_timeout);
    const self = this;
    this.class_label_timeout = setTimeout(() => {
      eventsTableComponent.setState(
        {
          class_label_filter: self.state.class_label_filter,
          device_filter: self.state.device_filter,
          loading: true,
        },
        eventsTableComponent.loadRequests,
      );
    }, 500);
  };

  handleDeviceFilterChange = (event) => {
    this.setState({
      class_label_filter: '',
      device_filter: event.target.value,
    });
    if (this.device_timeout) clearTimeout(this.device_timeout);
    const self = this;
    this.device_timeout = setTimeout(() => {
      eventsTableComponent.setState(
        {
          class_label_filter: self.state.class_label_filter,
          device_filter: self.state.device_filter,
          loading: true,
        },
        eventsTableComponent.loadRequests,
      );
    }, 500);
  };

  renderDatePicker = (params) => {
    const parse = str => str ? dayjs(str) : undefined;
    return (
      <Tooltip title={params.tooltip}>
        <DateLabel
          label={params.name}
          labelPlacement="start"
          style={{ marginLeft: 0, marginRight: params.marginRight }}
          control={
            <DatePicker
              value={parse(params.value)}
              minDate={parse(params.minValue)}
              maxDate={parse(params.maxValue)}
              onChange={params.handler}
              clearable
              format={'YYYY-MM-DD'}
              disableFuture
            />
          }
        />
      </Tooltip>
    );
  }

  renderTimePicker = (params) => {
    const timeValue = (
      params.value ? dayjs('2001-01-01T' + params.value) : undefined);
    return (
      <Tooltip title={params.tooltip}>
        <DateLabel
          label={params.name}
          labelPlacement="start"
          style={{ marginLeft: 0, marginRight: params.marginRight }}
          control={
            <TimePicker
              value={timeValue}
              onChange={params.handler}
              inputFormat={'HH:mm'}
              ampm={false}
            />
          }
        />
      </Tooltip>
    );
  }

  updateTableTimeFilter = () => {
    this.validateTimeFilter();

    if (this.time_filter_timeout)
      clearTimeout(this.time_filter_timeout);

    const self = this;
    this.time_filter_timeout = setTimeout(() => {
      eventsTableComponent.setState(
        {time_filter: self.state.time_filter, loading: true},
        eventsTableComponent.loadRequests,
      );
    }, 4000);
  }

  isEmpty = (obj) => {
    if (obj === undefined) return true;
    if (obj === null) return true;
    if (obj === "") return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }

  validateTimeFilter = () => {
    const warnings = [];
    ['start', 'end'].forEach((when) => {
      const time_name = "time_" + when;
      const date_name = "date_" + when;
      if (!this.isEmpty(this.state.time_filter[time_name]) &&
          this.isEmpty(this.state.time_filter[date_name]))
        warnings.push("Please select " + when + " date as well");
    });
    this.setState({ time_filter: {
      ...this.state.time_filter,
      warnings: warnings
    }});
  }

  handleDateChange = (name) => {
    return (date) => {
      let newDate = null;
      if (date === null || !Number.isNaN(date.valueOf())) {
        if (date !== null) newDate = [
            date.year(),
            zeroFill(date.month() + 1),
            zeroFill(date.date()),
          ].join('-');

        const newState = { time_filter: {
          ...this.state.time_filter,
          [name]: newDate
        } };
        this.setState(newState, this.updateTableTimeFilter);
      }
    };
  }

  handleTimeChange = (name) => {
    return (time) => {
      let newTime = null;
      if (time === null || !Number.isNaN(time.valueOf())) {
        if (time !== null) newTime = [
            zeroFill(time.hour()),
            zeroFill(time.minute()),
          ].join(':');
        const newState = { time_filter: {
          ...this.state.time_filter,
          [name]: newTime
        } };
        this.setState(newState, this.updateTableTimeFilter);
      }
    };
  }

  renderTimeFilter = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <fieldset style={{ marginBottom: '0.5rem' }}>
          <legend>Date and time filter</legend>
          <Grid item>
            {this.renderDatePicker({
              name: 'Start date',
              tooltip: 'Start date when the sound clip was submitted',
              value: this.state.time_filter.date_start,
              maxValue: this.state.time_filter.date_end,
              handler: this.handleDateChange('date_start'),
              marginRight: '3rem',
            })}
            {this.renderDatePicker({
              name: 'End date',
              tooltip: 'End date when the sound clip was submitted',
              value: this.state.time_filter.date_end,
              minValue: this.state.time_filter.date_start,
              handler: this.handleDateChange('date_end'),
              marginRight: 0,
            })}
          </Grid>
          <Grid item>
            {this.renderTimePicker({
              name: 'Start time',
              tooltip: 'Start time of day when the sound clip was submitted',
              value: this.state.time_filter.time_start,
              handler: this.handleTimeChange('time_start'),
              marginRight: '3rem',
            })}
            {this.renderTimePicker({
              name: 'End time',
              tooltip: 'End time of day when the sound clip was submitted',
              value: this.state.time_filter.time_end,
              handler: this.handleTimeChange('time_end'),
              marginRight: 0,
            })}
          </Grid>
          <p style={{ fontSize: "80%", marginTop: "1rem", marginBottom: "0" }}><em>
            Note: the timestamps are in UTC timezone.
            Swedish local CEST timezone is +2:00 hours.</em></p>
          { this.state.time_filter.warnings.length > 0 && (
          <Grid item>
            { this.state.time_filter.warnings.map((warning) => (
              <Alert elevation={6} variant="filled" severity="warning">
                { warning }
              </Alert>
            ))}
          </Grid>
          )}
        </fieldset>
      </LocalizationProvider>
    );
  }

  render() {
    const skipDaysLabel = ` Skip last ${
      this.state.skipDays
    } days ${String.fromCharCode(160)}${String.fromCharCode(160)}`;
    return (
      <fieldset style={{ marginBottom: '1rem', width: '54rem' }}>
        <legend>Filter settings</legend>
        <Grid container>

          <Grid item>
            <Tooltip title="Show the least confident predictions">
              <FormControlLabel
                label="Unconfident"
                style={this.formControlStyle}
                control={
                  <Checkbox
                    name="unconfident"
                    checked={this.state.unconfident}
                    onChange={this.handleUnconfidentChange}
                    style={{ marginLeft: '0.5rem' }}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Only show audio clips that have been annotated">
              <FormControlLabel
                label="Annotated"
                style={this.formControlStyle}
                control={
                  <Checkbox
                    name="annotated"
                    checked={this.state.annotated}
                    onChange={this.handleAnnotatedChange}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Show most confident mispredictions. WARNING: take a long time to load. ">
              <FormControlLabel
                label="Mispredictions"
                style={this.formControlStyle}
                control={
                  <Checkbox
                    name="mispredictions"
                    checked={this.state.mispredictions}
                    onChange={this.handleMispredictionsChange}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Skip audio clips that have been annotated in the last X days">
              <FormControlLabel
                label={skipDaysLabel}
                labelPlacement="start"
                style={this.formControlStyle}
                control={
                  <Slider
                    value={parseInt(this.state.skipDays)}
                    aria-valuetext="Skip last X days"
                    aria-labelledby="skip-last-days"
                    valueLabelDisplay="auto"
                    style={{ marginLeft: '0.3rem', minWidth: '6rem' }}
                    onChange={this.handleSkipDaysChange}
                    onChangeCommitted={this.handleSkipDaysCommit}
                    marks
                    max={10}
                  />
                }
              />
            </Tooltip>
            <FormControl
              style={{
                width: '7rem',
                marginRight: 0,
                marginBottom: '0.6rem',
                marginTop: '-0.5rem' }}
            >
              <Select
                id="max_similarity"
                value={this.state.max_similarity}
                style={{ paddingBottom: 0 }}
                size="small"
                onChange={this.handleMaxSimChange}
              >
                {this.maxSimilarityItems.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Max similarity</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl style={this.formControlStyle}>
              <Tooltip title="Enter prefix to filter the device with">
                <TextField
                  id="device-filter"
                  variant="outlined"
                  label="Device"
                  value={this.state.device_filter}
                  onChange={this.handleDeviceFilterChange}
                  style={{ padding: '0', margin: '0', width: '8rem' }}
                  margin="dense"
                  size="small"
                />
              </Tooltip>
              <FormHelperText>Device filter</FormHelperText>
            </FormControl>
            <FormControl style={this.formControlStyle}>
              <Tooltip title="Enter prefix to filter the class label with">
                <TextField
                  id="class-label-filter"
                  variant="outlined"
                  label="Class label"
                  value={this.state.class_label_filter}
                  onChange={this.handleCLFilterChange}
                  style={{ padding: '0', margin: '0', width: '8rem' }}
                  margin="dense"
                  size="small"
                />
              </Tooltip>
              <FormHelperText>Class label filter</FormHelperText>
            </FormControl>
            <FormControl style={this.formControlStyle}>
              <Select
                id="predict-event-filter"
                value={this.state.predictEventFilter}
                style={{ width: '9rem', paddingBottom: 0 }}
                size="small"
                onChange={this.handlePredFilterChange}
              >
                {this.state.events.map((e, i) => (
                  <MenuItem key={i} value={e.name}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Prediction event filter</FormHelperText>
            </FormControl>
            <FormControl style={this.formControlStyle}>
              <Select
                id="annotation-event-filter"
                value={this.state.annotationEventFilter}
                style={{ width: '9rem', paddingBottom: 0 }}
                size="small"
                onChange={this.handleAnnFilterChange}
              >
                {this.state.events.map((e, i) => (
                  <MenuItem key={i} value={e.name}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Annotation event filter</FormHelperText>
            </FormControl>
            <FormControl style={this.formControlStyle}>
              <Select
                id="org-filter"
                value={this.state.orgFilter}
                style={{ width: '8rem', paddingBottom: 0 }}
                size="small"
                onChange={this.handleOrgFilterChange}
              >
                {this.state.orgs.map((o, i) => (
                  <MenuItem key={i} value={o.name}>
                    {o.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Organization filter</FormHelperText>
            </FormControl>
          </Grid>

          { this.renderTimeFilter() }
        </Grid>
      </fieldset>
    );
  }
}

class SimilarEventsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      only_unannotated: getLocationParam('only_unannotated') === 'true',
      only_annotated: getLocationParam('only_annotated') === 'true',
    };
    this.handleAnnotatedChange = this.handleAnnotatedChange.bind(this);
  }

  handleAnnotatedChange = (event) => {
    const newState = { [event.target.name]: event.target.checked };
    this.setState(newState);
    const tableState = { ...newState, loading: true };
    eventsTableComponent.setState(
      tableState,
      eventsTableComponent.loadRequests,
    );
  };

  render() {
    return (
      <Grid container style={{ paddingBottom: '1rem' }}>
        <FormControlLabel
          label="Only unannotated"
          control={
            <Checkbox
              name="only_unannotated"
              checked={this.state.only_unannotated}
              onChange={this.handleAnnotatedChange}
            />
          }
        />
        <FormControlLabel
          label="Only annotated"
          control={
            <Checkbox
              name="only_annotated"
              checked={this.state.only_annotated}
              onChange={this.handleAnnotatedChange}
            />
          }
        />
      </Grid>
    );
  }
}

class EventsTable extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      requests: null,
      rowsPerPage: 100,
      pageNum: 0,
      loading: false,
      max_similarity: '0',
      class_label_filter: '',
      device_filter: '',
      time_filter: null,
      only_unannotated: getLocationParam('only_unannotated') === 'true',
      only_annotated: getLocationParam('only_annotated') === 'true',
    };
    setLocationState(this.state);
    this.cookies = new Cookies();
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.loadRequests = this.loadRequests.bind(this);
    eventsTableComponent = this;
  }

  componentDidMount() {
    this.loadRequests();
  }

  handleChangePage(event, newPage) {
    this.setState({ loading: true, pageNum: newPage }, this.loadRequests);
  }

  handleChangeRowsPerPage(event) {
    this.setState(
      { loading: true, pageNum: 0, rowsPerPage: +event.target.value },
      this.loadRequests,
    );
  }

  loadRequests() {
    // fetch existing data from the API
    const searchParams = this.buildSearchParams();
    const newPath = `/?${searchParams.toString()}${window.location.hash}`;
    window.history.pushState({}, '', newPath);
    buildRequestsUrl(this.state, searchParams, this.context, url =>
      fetchApiRequest(url, fetch => fetch
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              data.loading = false;
              this.setState(data);
              // cache clips in cookie, to speed up navigation
              const saferAnnotationsCache = data.requests.map((c) => c.url);
              this.cookies.remove('saferAnnotationsCache');
              this.cookies.set(
                'saferAnnotationsCache',
                saferAnnotationsCache,
                { maxAge: 3600, sameSite: 'lax' },
              );
            });
          } else {
            res.text().then((txt) => {
              this.setState({
                requests: {
                  detail: `Fetch requests ${res.status} error: ${txt}`,
                },
              });
            });
          }
        })
        .catch((error) => {
          console.log(`Fetch requests error: ${error}`);
          this.setState({
            requests: {
              detail: `Fetch requests error: ${error}`,
            },
          });
        })
      )
    )
  }

  buildSearchParams() {
    const params = buildLocationParams(this.state);
    const offset = this.state.rowsPerPage * this.state.pageNum;
    params.set('offset', offset);
    params.set('limit', this.state.rowsPerPage);
    return params;
  }

  showEvents(row) {
    let events = Object.entries(row.events)
      .map((e, i) => (
        <span key={i}>
          {e[1].toPrecision(4)}: {e[0]}
        </span>
      ))
      .reduce((acc, cur) => (acc === null ? cur : [acc, ', ', cur]), null);
    if (events === null) events = '<no events>';
    return events;
  }

  showLink(row, index) {
    const audioIndex = this.state.rowsPerPage * this.state.pageNum + index;
    const href =
      `/?audio_url=${row.url}&audio_index=${audioIndex}` +
      `&${buildLocationParams(this.state).toString()}#/annotations/event`;
    return <Link href={href}>{row.server_timestamp.substring(0, 16)}</Link>;
  }

  render() {
    if (this.state.requests === null) return <LinearProgress />;
    if (this.state.requests.detail !== undefined)
      return (
        <Alert elevation={6} variant="filled" severity="error">
          ERROR: {this.state.requests.detail}
        </Alert>
      );

    return (
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Client IP</TableCell>
              <TableCell>Device ID</TableCell>
              <TableCell>Class Label</TableCell>
              <TableCell>Events</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.requests.map((row, index) => (
              <TableRow key={index}>
                <TooltipTableCell>{this.showLink(row, index)}</TooltipTableCell>
                <TooltipTableCell>{row.host_ip}</TooltipTableCell>
                <TooltipTableCell>{row.device_id}</TooltipTableCell>
                <TooltipTableCell>{row.class_label}</TooltipTableCell>
                <TooltipTableCell>{this.showEvents(row)}</TooltipTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          rowsPerPage={this.state.rowsPerPage}
          count={-1}
          labelDisplayedRows={({ from, to }) => `Rows: ${from} - ${to}`}
          page={this.state.pageNum}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          style={{ margin: 0 }}
        />

        {loadingBackdrop({ open: this.state.loading })}
      </TableContainer>
    );
  }
}

export default withRouter(AnnotationEvents);

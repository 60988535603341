import { useGoogleLogin } from 'react-google-login';
import React, { FC, createContext, useState, ReactNode } from 'react';
import Cookies from 'universal-cookie';

export const clientId = "423589948832-v5k5b0e46m8nqn19qktsuou634oondin.apps.googleusercontent.com";
const saferCookies = new Cookies();
window.saferGoogleToken = saferCookies.get('saferGoogleToken');

export const defaultUser = {
  profile: undefined,
  loaded: false,
  handleLogin: (response: any) => {},
}

export const UserContext = createContext(defaultUser);

const handleLoginProfile = (response, setProfile) => {
  if (response === undefined) {
    window.saferGoogleToken = undefined;
    window.saferApiHost = undefined;  // it might be different next login
    saferCookies.remove('saferGoogleToken');
    setProfile(undefined);
  } else {
    window.saferGoogleToken = response.tokenId;
    saferCookies.set('saferGoogleToken', window.saferGoogleToken, {
      sameSite: 'none',
      secure: true,
      path: '/'
    });
    setProfile(response.profileObj);
  }
}

export const LoginContext: FC<{ children?: ReactNode }> = ({ children }) => {
  const [profile, setProfile] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useGoogleLogin({
    clientId: clientId,
    isSignedIn: true,
    onSuccess: (response: any) => {
      handleLoginProfile(response, setProfile);
      setLoaded(true)},
    onFailure: (error: any) => {
      console.log('LoginContext, onFailure: ' + JSON.stringify(error));
      handleLoginProfile(undefined, setProfile);
      setLoaded(true)},
  });

  const handleLogin = response => handleLoginProfile(response, setProfile);
  return (
    <UserContext.Provider value={{profile, loaded, handleLogin}}>
      { children }
    </UserContext.Provider>
  );
}

export const FakeLoginContext: FC<{ children?: ReactNode }> = ({ children }) => {
  const [profile, setProfile] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const handleLogin = (response) => {
    handleLoginProfile(response, setProfile);
    setLoaded(true);
  };
  return (
    <UserContext.Provider value={{profile, loaded, handleLogin}}>
      { children }
    </UserContext.Provider>
  );
}

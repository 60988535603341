import React, { useMemo } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  SxProps,
  Theme
} from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';

export interface ColumnType {
  name: string;
  header: string;
  format?: (v: any) => string;
  align?: TableCellProps['align'];
  color?: string;
  zeroBased?: boolean;
}

export interface TableData {
    columns: ColumnType[];
    rows: string[][];
}

interface CustomTableProps {
    data: TableData;
    maxEvents?: number;
    eventTime?: string;
    timestampName?: string;
    filterUpdate?: ({startTime, endTime}) => void;
    sx?: SxProps<Theme>;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '0.2rem 0.5rem',
  },
  '&.MuiTableCell-body': {
    padding: '0.2rem 0.5rem',
  },
}));

interface StyledTableRowProps {
  isClosest: boolean;
}

const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
    ({ theme, isClosest }) => ({
  backgroundColor: isClosest ? '#ffcccc' : 'inherit',
  '&:nth-of-type(odd)': {
    backgroundColor: isClosest ? '#ffcccc' : '#f5f5f5',
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const PrettyTable: React.FC<CustomTableProps> = (
  { data, eventTime, timestampName, maxEvents, filterUpdate, sx }) => {
  const { columns, rows } = data;
  let closestIndex = 0;

  const filteredRows = useMemo(() => {
    const targetTime = new Date(eventTime).getTime();

    // Find event index closest to targetTime
    let smallestDifference = Infinity;
    for (; closestIndex < rows.length; closestIndex++) {
      const row = rows[closestIndex];
      const rowTime = new Date(row[timestampName]).getTime();
      // Assume ascending timestamps
      const difference = Math.abs(targetTime - rowTime);
      if (difference < smallestDifference)
        smallestDifference = difference
      else
        break;
    }

    // Select the middle maxEvents rows
    const halfMaxEvents = Math.floor(maxEvents / 2) + 1;
    let startIndex = Math.max(0, closestIndex - halfMaxEvents);
    if (rows.length - startIndex < maxEvents) {
      startIndex = Math.max(0, rows.length - maxEvents);
    }
    const endIndex = Math.min(rows.length - 1, startIndex + maxEvents);
    // Set closestIndex relative to startIndex, to make highlighting work
    closestIndex -= startIndex + 1;
    if (filterUpdate) filterUpdate({
      startTime: rows[startIndex][timestampName],
      endTime: rows[endIndex][timestampName],
    });

    return rows.slice(startIndex, endIndex + 1);
  }, [rows, columns, eventTime, maxEvents]);

  const renderBarCell = (col: ColumnType, value: number) => {
    let percentage = (value / 1.0) * 100;
    if (col.zeroBased)
      percentage = 100 - percentage;
    percentage = Math.max(1, percentage);
    const alpha = 0.3;

    return (
      <div style={{
        position: 'relative', width: '100%', height: '1.3rem',
        display: 'flex', justifyContent: 'flex-end' }}>
        <div
          style={{
            width: `${percentage}%`,
            backgroundColor: `rgba(${col.color}, ${alpha})`,
            height: '100%',
          }}>
          <span style={{
            position: 'absolute', left: '50%', color: '#000' }}>
            {col.format ? col.format(value) : value}
          </span>
        </div>
      </div>
    );
  };

  return (
    <TableContainer component={Paper} sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, colIndex) => (
              <StyledTableCell key={colIndex} align={col.align}>
                {col.header}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row, rowIndex) => (
            <StyledTableRow
                key={rowIndex}
                isClosest={rowIndex === closestIndex}>
              {columns.map((col, colIndex) => (
                <StyledTableCell key={colIndex} align={col.align}>
                  {typeof row[col.name] === "number" ?
                    renderBarCell(col, row[col.name]) :
                    col.format(row[col.name])}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// const tableData = {
//   columns: [
//       name: 'age',
//       header: 'Age',
//       format: v => v.toFixed(4),
//       align: 'right',
//       color: '0, 128, 0',
//       zeroBased: false },
//     },
//   rows: [
//       ['John Doe', '30', 'New York'],
//       ['Jane Smith', '25', 'London'],
//       ['Bob Johnson', '35', 'Paris']
//   ],
// };

export default PrettyTable;

/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Topbar from './Topbar';

window.saferApiHost = undefined;

export function getApiHost(callback) {
  if (window.saferApiHost === undefined) {
    if (window.location.hostname === 'localhost')
      window.saferApiHost = 'http://localhost:8080';
    else {
      const { protocol } = window.location;
      if (isProd())
        window.saferApiHost = `${protocol}//api.ser.safereaction.com`;
      else if (process.env.REACT_APP_K8S_OVERLAY === 'dev')
        window.saferApiHost = `${protocol}//local.api.ser.safereaction.com`;
      else // for microk8s environment
        // TODO: this should not work, process.env is replaced during build
        window.saferApiHost = (
          `${protocol}//${process.env.REACT_APP_API_SERVICE_HOST}`);
    }
  }
  callback(window.saferApiHost);
}

export function isProd() {
  return process.env.REACT_APP_K8S_OVERLAY === 'gke';
}

export function fetchApiRequest(url, callback, init={}) {
  if (window.saferGoogleToken) {
    const { headers, ...initNoHeaders } = init;
    const requestInit = {
      headers: {
        'Authorization': 'Google ' + window.saferGoogleToken,
        ...headers
      },
      ...initNoHeaders
    };
    const fetchPromise = fetch(url, requestInit);
    fetchPromise.then((res) => {
      if (res.status === 401) {
        window.saferGoogleToken = undefined;
        window.location.replace('/#/login');
      }
      else
        callback(fetchPromise);
    })
  } else {
    window.history.pushState({}, '', '/#/login');
  }
}

export function buildRequestsUrl(state, params, context, callback) {
  getApiHost((apiHost) => {
    const apiMethod = state.similar
      ? 'similar_requests'
      : 'annotation_requests';
    const requestsUrl = `${apiHost}/v1/${apiMethod}?${params.toString()}`;
    callback(requestsUrl);
  });
}

export function getLocationParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

export function updateLocationSearchParams(params) {
  const search = new URLSearchParams(window.location.search);
  Object.entries(params).map((e) => search.set(e[0], e[1]));
  window.location.search = search.toString();
}

export function loadConfig(params) {
  const search = new URLSearchParams(window.location.search);
  Object.entries(params).map((e) => search.set(e[0], e[1]));
  window.location.search = search.toString();
}

export const TableCellHead = withStyles({
  root: {
    fontWeight: 'bold',
    verticalAlign: 'top',
  },
})(TableCell);

export function ifdef(variable, fun = (v) => v) {
  if (typeof variable !== 'undefined')
    return fun(variable);
  else
    return <i>&lt;undefined&gt;</i>;
}

export function toString(value) {
  return value === null ? '' : value;
}

export class SnackAlert extends React.Component {
  constructor(props) {
    super(props);
    this.hideDuration =
      props.hideDuration === undefined ? 6000 : props.hideDuration;
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(event, reason) {
    const { parent, state } = this.props;
    if (reason !== 'clickaway') {
      parent.setState({ [state]: false });
    }
  }

  render() {
    const { parent, state, severity, children } = this.props;
    return (
      <Snackbar
        open={parent.state[state]}
        autoHideDuration={this.hideDuration}
        onClose={this.handleClose}
        disableWindowBlurListener
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleClose}
          severity={severity}
        >
          {children}
        </MuiAlert>
      </Snackbar>
    );
  }
}

export function renderBoilerPlate(children) {
  return (<>
    <CssBaseline />
    <Topbar />
    { children }
  </>);
}

export function loadingBackdrop({ open }) {
  return (
    <Backdrop open={open} style={{ zIndex: 1500, color: '#fff' }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export class TooltipTableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowed: false,
    };
    this.textElement = React.createRef();
  }

  componentDidMount() {
    const { scrollWidth, clientWidth } = this.textElement.current;
    this.setState({ isOverflowed: scrollWidth > clientWidth });
  }

  render() {
    const { isOverflowed } = this.state;
    const { children } = this.props;
    return (
      <Tooltip
        interactive="true"
        title={children || ''}
        disableHoverListener={!isOverflowed}
      >
        <TableCell ref={this.textElement}>{children}</TableCell>
      </Tooltip>
    );
  }
}

export function loadEvents(callback, audio_url) {
  getApiHost(apiHost => {
    let url = `${apiHost}/v1/events`;
    if (audio_url !== undefined)
      url += `?audio_url=${audio_url}`
    fetchApiRequest(url, fetch => fetch
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            data.events.push({id: 999, name: 'Ignore'})
            callback(data.events)
          });
        else
          res.text().then(error => alert(
            `Fetch events response error: ${error}`));
      })
      .catch(error => alert(`Fetch events error: ${error}`))
    );
  });
}

export function loadOrgs(context, callback) {
  getApiHost(apiHost => {
    let url = `${apiHost}/v1/orgs`;
    fetchApiRequest(url, fetch => fetch
      .then(res => {
        if (res.ok)
          res.json().then(data => callback(data.orgs));
        else
          res.text().then(error => alert(
            `Fetch orgs response error: ${error}`));
      })
      .catch(error => alert(`Fetch orgs error: ${error}`))
    );
  });
}

export function zeroFill(value) {
  const number = parseInt(value);
  if (number < 10) return `0${number}`;
  return number.toString();
}

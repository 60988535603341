import React, { useCallback, useContext, useEffect, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { clientId, UserContext } from './UserContext';
import Grid from '@mui/material/Grid';

import { getApiHost, isProd, renderBoilerPlate } from './Common';
import { Button, Paper, Typography } from '@mui/material';

declare global {
  interface Window {
    saferGoogleToken: any;
    saferApiHost: any;
  }
}

const Login: React.FC<RouteComponentProps> = () => {
  const { profile, handleLogin } = useContext(UserContext);
  // eslint-disable-next-line
  const [ alert, setAlert ] = useState(undefined);
  // eslint-disable-next-line
  const fakeResponse = {
    tokenId: 'fakeToken',
    profileObj: {
      givenName: 'fakeName',
      email: 'fake@email.org',
    },
  }

  const isAuth = useCallback(() => profile !== undefined, [profile]);
  const handleFakeLogin = () => handleLogin(fakeResponse);

  const performLogout = useCallback(() => {
    handleLogin(undefined);
  }, [handleLogin]);

  const performLogin = useCallback(response => {
    setAlert(undefined);
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/google_auth`;
      const apiInit = {
        headers: {
          'Authorization': 'Google ' + response.tokenId }};
      fetch(apiUrl, apiInit)
        .then(res => res.json())
        .then(authResponse => {
          if (authResponse.status === 'Valid') {
            setTimeout(performLogout, 10 * 60 * 1000); // to avoid expiring token
            const apiResponse = {
              tokenId: response.tokenId,
              profileObj: authResponse};
            handleLogin(apiResponse);
          }
          else {
            setAlert('Invalid authorization: ' + JSON.stringify(authResponse));
            handleLogin(undefined);
          }
        })
        .catch(e => {
          const errorStr = JSON.stringify(e);
          console.log(`Login, handleLogin, fetch event error: ${errorStr}`);
          setAlert(`Login failure: ${errorStr}`);
        });
    });
  }, [handleLogin, performLogout]);

  // eslint-disable-next-line
  const handleFailure = (error) => {
    console.log('Login failure: ' + JSON.stringify(error));
    setAlert(error.details);
    performLogout();
  };

  useEffect(() => {
    // if window.saferGoogleToken is empty, make sure not auth
    if (isAuth() && window.saferGoogleToken === undefined) {
      performLogout();
      return;
    }

    // if authenticated and redir is given, redirect back
    const params = new URLSearchParams(window.location.search.substring(1));
    const redir = params.get("redir");
    if (isAuth() && redir !== null) {
      window.location.replace(redir);
      return;
    }
    // if not auth but cookie token exists, then login through the backend
    if (!isAuth() && window.saferGoogleToken !== undefined) {
      performLogin({tokenId: window.saferGoogleToken});
      return;
    }
  }, [isAuth, performLogin, performLogout]);

  return renderBoilerPlate(
      <Grid container>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Google Authentication</Typography>
            {isProd() ? (

              isAuth() ? (<>
                <GoogleLogout
                  clientId={clientId}
                  onLogoutSuccess={performLogout}
                  onFailure={() => handleFailure("GoogleLogout")}
                  buttonText="Logout"
                />
              </>) : (<>
                <GoogleLogin
                  clientId={clientId}
                  onSuccess={performLogin}
                  onFailure={handleFailure}
                  buttonText="Login"
                />
              </>)

            ) : (

              isAuth() ?
                <Button variant='contained' onClick={performLogout}>
                  Fake Logout
                </Button>
                :
                <Button variant='contained' onClick={handleFakeLogin}>
                  Fake Login
                </Button>

            )}
          </Paper>
          {alert !== undefined && (
            <MuiAlert elevation={6} variant="filled" severity="error">
              Error: { alert }
            </MuiAlert>
          )}
        </Grid>
      </Grid>
  );
}

export default withRouter(Login);
// eslint-disable-next-line max-classes-per-file
import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Iframe from 'react-iframe';
import Chart from 'react-google-charts';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { fetchApiRequest, getApiHost, renderBoilerPlate } from './Common';
import { UserContext } from './UserContext.tsx';
import VegaChart from './VegaChart';

// https://canvasjs.com/react-charts/multi-series-area-chart/
import CanvasJSReact from '../canvasjs.react';

class Dashboard extends Component {
  static contextType = UserContext;

  render() {
    const user = this.context;
    // do not show annotation info nor dashboard for famb customers
    const showAnnotationInfo = user.profile && user.profile.org !== 'famb';
    return renderBoilerPlate(
      <Grid container>
        { showAnnotationInfo && (<>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Annotator Stats</Typography>
            <AnnotatorStats />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Annotation Stats</Typography>
            <AnnotationEventStats />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Event Annotations Stats</Typography>
            <NumEventAnnotations />
          </Paper>
        </Grid>
        </>) }
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Audio Clip Stats</Typography>
            <AudioClipStats />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Audio Clip Daily Stats</Typography>
            <AudioClipDailyStats />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Prediction Stats</Typography>
            <PredictionEventStats saved_events={false} />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Saved Prediction Stats</Typography>
            <PredictionEventStats saved_events={true} />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Typography variant="subtitle1">Confusion Matrices</Typography>
            <Typography variant="body2">
              Ratio of correctly and wrongly predicted events based on the
              validation data.
            </Typography>
            <ConfusionMatrices />
          </Paper>
        </Grid>
        { showAnnotationInfo && (<>
        <Grid item xs={12}>
          <DataStudioReport />
        </Grid>
        </>)}
      </Grid>,
    );
  }
}

class AnnotatorStats extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      annotators: null,
    };
  }

  componentDidMount() {
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/annotation_stats`;
      fetchApiRequest(apiUrl, (fetch) =>
        fetch
          .then((res) => res.json())
          .then((data) => this.setState(data))
          .catch((e) => console.log(`fetch event error: ${e}`)),
      );
    });
  }

  render() {
    const { annotators } = this.state;
    if (!annotators) return <LinearProgress />;

    const dataPoints = Object.entries(annotators).map(
      (annotator) => {
        return {
          name: annotator[0],
          type: 'stackedArea',
          showInLegend: true,
          xValueFormatString: 'YYYY-MM-DD',
          dataPoints: annotator[1].map((day) => {
            return {
              x: new Date(day.date),
              y: day.count,
            };
          }),
        };
      },
    );
    const options = {
      height: 300,
      toolTip: { shared: true },
      axisY: { title: 'Num annotations per IP address' },
      data: dataPoints,
    };
    return (
      <div style={{ width: '731px' }}>
        <CanvasJSReact.CanvasJSChart options={options} />
      </div>
    );
  }
}

class AnnotationEventStats extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      requests: null,
    };
  }

  componentDidMount() {
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/num_annotation_requests`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => this.setState({ requests: data }))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }

  render() {
    if (this.state.requests === null) return <LinearProgress />;

    const dataPoints = Object.entries(this.state.requests).map((req) => {
      return {
        name: req[0],
        type: 'stackedArea',
        showInLegend: true,
        xValueFormatString: 'YYYY-MM-DD',
        dataPoints: Object.entries(req[1]).map((day) => {
          return {
            x: new Date(day[0]),
            y: day[1],
          };
        }),
      };
    });
    const options = {
      height: 300,
      toolTip: { shared: true },
      axisY: { title: 'Num annotations per request' },
      data: dataPoints,
    };
    return (
      <div style={{ width: '731px' }}>
        <CanvasJSReact.CanvasJSChart options={options} />
      </div>
    );
  }
}

class AudioClipStats extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      clip_stats: null,
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    getApiHost(apiHost => {
      const apiUrl = `${apiHost}/v1/audio_clip_stats`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => this.setState({ clip_stats: data }))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }

  render() {
    if (this.state.clip_stats === null)
      return <LinearProgress />;

    const chart_data = [
        ['Device ID', '# audio clips'],
    ].concat(Object.entries(
        this.state.clip_stats
      ).sort(
        (a, b) => a[1] > b[1] ? -1 : 1
      ).map(e => [e[0], e[1]])
    );
    const chart_height = 50 + chart_data.length * 22;
    return (
      <Chart
        width="731px"
        height={chart_height + "px"}
        chartType="BarChart"
        data={chart_data}
        options={{
          legend: { position: 'bottom' },
          isStacked: true,
          vAxis: { title: 'device_id' },
          hAxis: { scaleType: 'log' },
          chartArea: { top: 10, bottom: 70 },
        }}
      />
    );
  }
}

class AudioClipDailyStats extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      clip_daily_stats: null,
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/audio_clip_daily_stats`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => this.setState({ clip_daily_stats: data }))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }

  render() {
    if (this.state.clip_daily_stats === null)
      return <LinearProgress />;

    return (
      <Chart
        width="731px"
        height="250px"
        chartType="ColumnChart"
        data={this.state.clip_daily_stats}
        options={{
          isStacked: true,
          legend: { position: 'none' },
          vAxis: { title: 'num clips' },
        }}
      />
    );
  }
}

class NumEventAnnotations extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      num_annotations: null,
    };
  }

  componentDidMount() {
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/num_event_annotations_request`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => this.setState({ num_annotations: data }))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }

  render() {
    if (this.state.num_annotations === null) return <LinearProgress />;
    const static_data = {
      sesa: {'Casual': 140, 'Siren': 90, 'Explosion': 170, 'Gunshot': 80},
      voice: {'Gunshot': 7046, 'Baby cry': 6412, 'Glass break': 7249},
      thunder: {'Raining': 734},
    }
    const data = [['Event', 'SESA', 'VOICe', 'Thunder', 'SAFER']].concat(
      Object.entries(this.state.num_annotations).sort((a, b) => {
        return a[1] > b[1] ? -1 : 1}).map((e => {return [
          e[0],
          static_data.sesa[e[0]] || 0,
          static_data.voice[e[0]] || 0,
          static_data.thunder[e[0]] || 0,
          e[1]
        ]})));
    return (
      <Chart
        width="731px"
        height="600px"
        chartType="BarChart"
        data={data}
        options={{
          title: 'Total number of annotations per event type',
          legend: { position: 'bottom' },
          isStacked: true,
        }}
      />
    );
  }
}

const PredictionEventStats = (props) => {
  const [ chartSpec, setChartSpec ] = useState(undefined);

  useEffect(() => {
    if (chartSpec) return;  // already loaded
    getApiHost(apiHost => {
      const { saved_events } = props;
      const apiUrl = `${apiHost}/v1/prediction_events_chart?` +
        `should_save=${saved_events}`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then(res => res.json())
        .then(json => setChartSpec(JSON.parse(json)))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }, [chartSpec]);

  if (!chartSpec) return <LinearProgress />;
  return <VegaChart spec={ chartSpec } />;
}

class ConfusionMatrices extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      matrices: null,
    };
  }

  componentDidMount() {
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/confusion_matrices`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => this.setState({ matrices: data }))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }

  render() {
    if (this.state.matrices === null) return <LinearProgress />;
    return this.state.matrices.map(cm =>
      <div key={cm.name}>
        <Typography variant="subtitle2">{cm.name} model</Typography>
        <img src={cm.path} alt={cm.name + "Confusion Matrix"} />
      </div>
    );
  }
}

class DataStudioReport extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      report_url: null,
    };
  }

  componentDidMount() {
    getApiHost((apiHost) => {
      const apiUrl = `${apiHost}/v1/data_studio_report`;
      fetchApiRequest(apiUrl, fetch => fetch
        .then((res) => res.json())
        .then((data) => this.setState({ report_url: data }))
        .catch((e) => console.log(`fetch request error: ${e}`))
      );
    });
  }

  render() {
    if (this.state.report_url === null) return <LinearProgress />;
    return <Iframe
      width="1000px"
      height="1400px"
      url={this.state.report_url}
    />
  }
}

export default withRouter(Dashboard);

export const AuthMenu = [
  {
    label: 'Dashboard',
    pathname: '/',
  },
  {
    label: 'Notifications',
    pathname: '/notification',
  },
  {
    label: 'Annotations',
    pathname: '/annotations',
  },
  // {
  //   label: 'Live',
  //   pathname: '/live',
  // },
  {
    label: 'Logout',
    pathname: '/login',
  }
];

export const LoginMenu = [
  {
    label: 'Login',
    pathname: '/login',
  }
];

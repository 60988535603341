import React from 'react';

import Grid from '@mui/material/Grid';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  getApiHost,
  fetchApiRequest,
} from '../components/Common';

// sync with schema.py, NotificationData
export interface NotificationData {
  createdAt?: Date;
  updatedAt?: Date;
  description: string;
  mail?: string;
  sms?: string;
  selectedEvents?: { [label: string]: boolean };
  selectedDevices?: { [label: string]: boolean };
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  minDigestTime?: string;
  positivePercent?: number;
  // extra states for the date pickers since they need need in Date objects
  dates?: { [name: string]: Date };
  digest?: string;
  checkMissingData?: boolean;
  checkAnomalyOnly?: boolean;
  anomalyThreshold?: number;
  anomalyWindowSize?: number;
  notificationId?: string;
}

interface EventData {
  name: string;
  id: number;
}

interface DeviceData {
  name?: string;
  organization?: string;
  clips?: string;
  added?: string;
}

interface State {
  notifications?: NotificationData[];
  availableEvents?: EventData[];
  availableDevices?: DeviceData[];
  rowsPerPage: number;
  pageNum: number;
  loading: boolean;
  editing?: NotificationData;
  error?: string;
}

class NotificationUtils {
  props: any;

  constructor(props: any) {
    this.props = props;
  }

  stringChange = (event) => {
    const newData = { ...this.props.data };
    newData[event.target.name] = event.target.value;
    this.props.setData(newData);
    this.props.setUnsaved(true);
  }

  eventChange = (name) => {
    return (event) => {
      const newData = { ...this.props.data };
      newData[name] = event.target.value;
      this.props.setData(newData);
      this.props.setUnsaved(true);
    }
  }

  checkedChange = (event) => {
    const newData = { ...this.props.data };
    newData[event.target.name] = event.target.checked;
    this.props.setData(newData);
    this.props.setUnsaved(true);
  }

  formLabel = styled(FormControlLabel)({
    width: '18rem', marginLeft: 'auto',
    fontWeight: 'bold', padding: '0.2rem 0.2rem'
  });

  handleCancel = () => {
    this.props.unloadNotification();
    this.props.loadPage();
  }

  handleSave = (event) => {
    event.preventDefault();
    this.submitNotification({
      info: 'Save notification',
      method: 'POST',
      callback: () => {
        this.props.unloadNotification();
        this.props.setUnsaved(false);
        this.props.loadPage();
      },
    });
  }

  handleDelete = () => {
    this.submitNotification({
      info: 'Delete notification',
      method: 'DELETE',
      callback: () => {
        this.props.unloadNotification();
        this.props.setUnsaved(false);
        this.props.loadPage();
      }
    });
  }

  handleVizLink = (e) => {
    e.preventDefault();
    if (this.props.unsaved) {
      if (!window.confirm(
          'You have unsaved changes. Are you sure that you want to leave ' +
          'this page before saving?'))
        return;
    }
    const params = new URLSearchParams();
    params.set('notification_id', this.props.data.notificationId);
    const newPath = `/?${params.toString()}#/notification/anomaly`;
    window.location.href = newPath;
  }

  submitNotification = ({ info, method, callback }) => {
    getApiHost((apiHost) => {
      const url = `${apiHost}/v1/notification`;
      const fetchInit = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.props.data),
      };
      const fetchCallback = fetch => fetch
        .then((res) => {
          if (res.ok) {
            res.json().then(callback);
          } else {
            res.text().then((error) => {
              this.props.setError(
                `${info} API ${res.status} error: ${error}`);
            });
          }
        })
        .catch((error) => {
          this.props.setError(`${info} error: ${error}`);
        });
      fetchApiRequest(url, fetchCallback, fetchInit);
    });
  }

  renderPaper = (title: string, content: React.ReactNode) => {
    return (
      <Paper>
        <Typography variant="subtitle1">{title}</Typography>
        {content}
      </Paper>
    );
  }

  renderAnomalySettings = ({ listPage }) => {
    if (!this.props || !this.props.data) return '';
    const sliderStyles = {
      width: '18rem', marginLeft: 'auto',
      fontWeight: 'bold', padding: '0.2rem 0.2rem'
    };
    return (
      <fieldset style={{ marginBottom: '0.5rem' }}>
        <legend>Anomaly detection settings</legend>
        {listPage && (
          <FormControlLabel
            label="Notify on anomaly events only"
            key="checkAnomalyOnly"
            control={<Checkbox
              checked={this.props.data.checkAnomalyOnly}
              name="checkAnomalyOnly"
              onChange={this.checkedChange}
              />}
            style={{
              marginLeft: '-0.3rem',
              marginBottom: '1rem' }}
          />
        )}
        <div>
          <Grid item>
            <Tooltip title="Anomaly deviation threshold">
              <FormControlLabel
                  label={<Typography sx={{width: '22rem'}}>
                  Deviation threshold</Typography>}
                labelPlacement="start"
                style={sliderStyles}
                control={
                  <Slider
                    size="small"
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    min={0.}
                    max={1.}
                    step={0.01}
                    value={this.props.data.anomalyThreshold}
                    valueLabelFormat={(value) => value.toFixed(2)}
                    onChange={this.eventChange('anomalyThreshold')}
                  />}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title="Number of events used to calculate window deviation">
            <FormControlLabel
              label={<Typography sx={{width: '22rem'}}>
                Window size</Typography>}
              labelPlacement="start"
              style={sliderStyles}
              control={
                <Slider
                  size="small"
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  min={2}
                  max={10}
                  step={1}
                  value={this.props.data.anomalyWindowSize}
                  onChange={this.eventChange('anomalyWindowSize')}
                />}
              />
            </Tooltip>
            {listPage && this.props.data.checkAnomalyOnly && (
              <p><Link
                  onClick={(e) => this.handleVizLink(e)}
                  style={{ cursor: 'pointer' }}
                >See historical anomaly events</Link></p>
            )}
          </Grid>
        </div>
      </fieldset>
    );
  }

  renderButtons = ({ listPage }) => {
    if (!this.props || !this.props.data) return;
    return (
      <Grid item style={{ marginTop: '1rem' }}>
        {listPage && (
          <Button
            type="button"
            variant="contained"
            onClick={this.handleCancel}
            style={{ marginRight: '1rem' }}
          >
            Cancel
          </Button>
        )}
        {listPage && this.props.data.createdAt !== undefined && (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={this.handleDelete}
            style={{ marginRight: '1rem' }}
          >
            Delete
          </Button>
        )}
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={this.handleSave}
          disabled={!this.props.unsaved}
        >
          Save
        </Button>
      </Grid>
    );
  }
};

export default NotificationUtils;

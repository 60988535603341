/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chart from 'react-google-charts';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';

import { renderBoilerPlate, getApiHost, fetchApiRequest } from './Common';
import { UserContext } from './UserContext.tsx';
// https://github.com/hackingbeauty/react-mic
import ReactMic from './ReactMic';

let eventPredictions = null;

class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (MediaRecorder.isTypeSupported('audio/ogg'))
      return renderBoilerPlate(
        <Grid container>
          <Grid item>
            <LiveMic />
          </Grid>
          <Grid item>
            <EventPredictions />
        </Grid>,
          </Grid>
      );
    return renderBoilerPlate(
      <Alert elevation={6} variant="filled" severity="error">
        ERROR: Your browser does not support audio/ogg format. Please try with a
        a browser like Firefox instead.
      </Alert>,
    );
  }
}

class LiveMic extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      predictionReady: true,
      record: false,
      pause: false,
      saveAudio: false,
    };
    this.onData = this.onData.bind(this);
    this.makePrediction = this.makePrediction.bind(this);
    this.downloadBlob = this.downloadBlob.bind(this);
    this.chunks = [];
    this.minChunks = 20;
    this.maxChunks = 40; // 4 secs
    getApiHost((apiHost) => {
      this.predictUrl = `${apiHost}/v1/predict`;
    });
    this.micRef = React.createRef();
    this.handleSaveAudioChange = this.handleSaveAudioChange.bind(this);
  }

  onData(chunk) {
    this.chunks.push(chunk);
    while (this.chunks.length > this.maxChunks) this.chunks.shift();
    const { predictionReady } = this.state;
    if (predictionReady && this.chunks.length >= this.minChunks)
      this.setState({ predictionReady: false }, this.makePrediction);
  }

  handleSaveAudioChange = (e, v) => this.setState({ saveAudio: v });

  makePrediction() {
    const blob = new Blob(this.chunks, { type: 'audio/ogg;codecs=opus' });
    const recorder = this.micRef.current.state.microphoneRecorder;
    recorder.restartRecording();
    const formData = new FormData();
    formData.append('audio_file', blob);
    const fetchInit = {
      credentials: 'include',
      method: 'POST',
      body: formData,
    };
    // note, the device_id will be set automatically on the server-side
    let apiUrl = this.predictUrl;
    const { saveAudio } = this.state;
    if (saveAudio) apiUrl += '?class_label=live';
    const callback = fetch => fetch
      .then((res) => res.json())
      .then((res) => {
        this.setState({ predictionReady: true });
        eventPredictions.addEventPredictions(res.events);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(`fetch error: ${JSON.stringify(e)}`);
        this.setState({ record: false });
      });
    fetchApiRequest(apiUrl, callback, fetchInit);
  }

  downloadBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(blob, filename);
    else {
      // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      const self = this;
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        self.setState({ predictionReady: true });
      }, 0);
    }
  }

  render() {
    const buttonStyle = { marginRight: '1rem' };
    const { record, pause, saveAudio } = this.state;
    return (
      <Paper>
        <Typography variant="subtitle1">Live audio recording</Typography>
        <Typography variant="body2"><p>Note: this is a beta function only for
        experimental purposes. The model has not been trained for the
        microphone in the computer, hence the performance will be poor.
        </p></Typography>
        <div>
          <Button
            style={buttonStyle}
            onClick={() => this.setState({ record: true, pause: false })}
            disabled={record}
            variant="contained"
            color="secondary"
          >
            Record
          </Button>
          <Button
            style={buttonStyle}
            onClick={() => this.setState({ record: false, pause: false })}
            disabled={!record}
            variant="contained"
          >
            stop
          </Button>
          <FormControlLabel
            labelPlacement="start"
            style={{ height: '1.8rem', marginLeft: 0 }}
            control={
              <Switch
                checked={saveAudio}
                onChange={this.handleSaveAudioChange}
                color="primary"
                name="saveAudio"
                style={{ bottom: '1px' }}
              />
            }
            label="Save Audio"
          />
        </div>
        <ReactMic
          ref={this.micRef}
          record={record} // defaults -> false.  Set to true to begin recording
          pause={pause} // defaults -> false (available in React-Mic-Gold)
          visualSetting="frequencyBars" // defaults -> "sinewave".  Other option is "frequencyBars"
          backgroundColor="white" // background color
          className="react-mic" // provide css class name
          echoCancellation // defaults -> false
          autoGainControl // defaults -> false
          noiseSuppression // defaults -> false
          channelCount={1} // defaults -> 2 (stereo).  Specify 1 for mono.
          bitRate={64000} // defaults -> 128000 (128kbps).  React-Mic-Gold only.
          sampleRate={12000} // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
          timeSlice={100} // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback (available in React-Mic-Gold).
          onStop={() => {}} // required - called when audio stops recording
          onData={this.onData} // optional - called when chunk of audio data is available
          onBlock={() => {}} // optional - called if user selected "block" when prompted to allow microphone access (available in React-Mic-Gold)
          strokeColor="red" // sinewave or frequency bar color
          mimeType="audio/ogg" // defaults -> "audio/webm".  Set to "audio/wav" for WAV or "audio/mp3" for MP3 audio format (available in React-Mic-Gold)
        />
      </Paper>
    );
  }
}

class EventPredictions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      predictions: [],
      eventColors: [],
    };
    this.eventNames = ['Time'];
    this.colorMap = {
      Casual: '#44FF44',
      Gunshot: '#FF0000',
      Explosion: '#AA0000',
      'Glass break': '#FFAA00',
      Thunder: '#0000FF',
      Siren: '#AAAAFF',
      'Human activity': '#4444FF',
      'Human speech': '#0044AA',
      'Baby cry': '#00AAFF',
    };
    this.maxPredictions = 30;
    eventPredictions = this;
    this.addEventPredictions = this.addEventPredictions.bind(this);
    this.buildChartData = this.buildChartData.bind(this);
  }

  addEventPredictions(events) {
    this.eventNames = this.eventNames
      .concat(events.map((e) => e.name))
      .filter((v, i, s) => s.indexOf(v) === i);
    const { colorMap } = this;
    const eventColors = this.eventNames
      .slice(1)
      .map((n) => colorMap[n] || 'black');
    const eventsObj = events.reduce(
      (o, e) => ({ ...o, [e.name]: e.score }),
      {},
    );
    eventsObj.Time = new Date();
    let { predictions } = this.state;
    predictions = predictions.concat([eventsObj]);
    if (predictions.length >= this.maxPredictions)
      predictions = predictions.slice(predictions.length - this.maxPredictions);
    this.setState({ predictions, eventColors });
  }

  buildChartData() {
    const { predictions } = this.state;
    const data = [this.eventNames].concat(
      predictions.map((p) => eventPredictions.eventNames.map((e) => p[e] || 0)),
    );
    return data;
  }

  render() {
    const { predictions, eventColors } = this.state;
    if (predictions.length === 0) return null;
    return (
      <Paper>
        <Typography variant="subtitle1">Live event predictions</Typography>
        <Chart
          chartType="AreaChart"
          data={this.buildChartData()}
          options={{
            chartArea: { width: '85%', height: '80%' },
            legend: { position: 'top', maxLines: 3 },
            isStacked: 'relative',
            enableInteractivity: true,
            connectSteps: false,
            height: 300,
            width: 642,
            hAxis: {
              format: 'mm:ss',
              maxTextLines: 1,
            },
            colors: eventColors,
          }}
        />
      </Paper>
    );
  }
}

export default withRouter(Live);

import AudioContext from './AudioContext'

let analyser
let audioCtx
let mediaRecorder
let stream
let mediaOptions
let onStartCallback
let onDataCallback
let constraints

navigator.getUserMedia = (navigator.getUserMedia
                          || navigator.webkitGetUserMedia
                          || navigator.mozGetUserMedia
                          || navigator.msGetUserMedia)

export class MicrophoneRecorder {

  constructor(onStart, onStop, onSave, onData, options, soundOptions) {
    const {
      echoCancellation,
      autoGainControl,
      noiseSuppression,
      channelCount
    } = soundOptions

    onStartCallback = onStart
    onDataCallback = onData
    mediaOptions = options

    constraints = {
      audio: {
        echoCancellation,
        autoGainControl,
        noiseSuppression,
        channelCount
      },
      video: false
    }
  }

  restartRecording() {
    if (mediaRecorder === null)
        return;
    mediaRecorder.stop();
    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      audioCtx = AudioContext.getAudioContext()
      audioCtx.resume().then(() => {
        analyser = AudioContext.getAnalyser();
        mediaRecorder.start(mediaOptions.timeSlice);
        const sourceNode = audioCtx.createMediaStreamSource(stream);
        sourceNode.connect(analyser);
      });
    });
  }

  startRecording() {
    if (mediaRecorder) {
      if (audioCtx && audioCtx.state === 'suspended') {
        audioCtx.resume()
      }

      if (mediaRecorder && mediaRecorder.state === 'paused') {
        mediaRecorder.resume()
        return
      }

      if (audioCtx && mediaRecorder && mediaRecorder.state === 'inactive') {
        mediaRecorder.start(mediaOptions.timeSlice)
        const source = audioCtx.createMediaStreamSource(stream)
        source.connect(analyser)
        if (onStartCallback) { onStartCallback() }
      }
    } else if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then((str) => {
          stream = str;
          if (MediaRecorder.isTypeSupported(mediaOptions.mimeType)) {
            mediaRecorder = new MediaRecorder(str, mediaOptions)
          } else {
            mediaRecorder = new MediaRecorder(str)
          }

          if (onStartCallback) { onStartCallback() }

          mediaRecorder.onstop = this.onStop
          mediaRecorder.ondataavailable = (event) => {
            if (onDataCallback) {
              onDataCallback(event.data)
            }
          }

          audioCtx = AudioContext.getAudioContext()
          audioCtx.resume().then(() => {
            analyser = AudioContext.getAnalyser()
            mediaRecorder.start(mediaOptions.timeSlice)
            const sourceNode = audioCtx.createMediaStreamSource(stream)
            sourceNode.connect(analyser)
          })
        })
    } else {
      alert('Your browser does not support audio recording')
    }
  }

  stopRecording() {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop()

      stream.getAudioTracks().forEach((track) => {
        track.stop()
      })
      mediaRecorder = null
      AudioContext.resetAnalyser()
    }
  }

  onStop() {
  }

  getMediaRecorder() {
    return mediaRecorder;
  }
}
